const md5 = require('md5');

class LoginStore {
  getDeviceUdid = () => {
    const userAgent = navigator.userAgent;
    return md5(`${userAgent}x${screen.height}x${screen.height}`);
  };
}

const store = new LoginStore();
export default store;
