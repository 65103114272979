const defaults = {
  optimizely: {
    enabled: false,
    sdkKey: '',
  },
  webpack: {
    fullstory: false,
  },
  mobileApp: {
    appRequired: false,
    downloadLinks: {
      android: 'javascript:;',
      ios: 'javascript:;',
    },
    downloadButton: {
      v2: false,
    },
  },
  index: {
    gtmID: false,
    primaryColor: '',
    backgroundColor: '',
    customerName: '',
    title: '',
    productionUrl: '',
    logoName: 'logo.png',
    logoLinked: 'logo.png',
  },
  tracking: {
    adnxs: {
      types: {
        deposit: `order_id=[ACCOUNT_ID]&value=[AMOUNT]&t=1`,
        login: `order_id=[ACCOUNT_ID]&t=1`,
        register: `order_id=[ACCOUNT_ID]&t=1`,
      },
    },
    airship: {
      enabled: false,
    },
    segment: {
      enabled: false,
      key: {
        dev: '',
        prod: '',
      },
    },
  },
  oneTrust: {
    enabled: false,
  },
  breadcumbs: {
    v1: true,
    v2: false,
  },
  responsibleGaming: {
    header: true,
    enabled: false,
    showInFooter: false,
    message: '',
    helplineNumber: '',
    url: '',
  },
  minimumBettingAge: 18,
  use404Layout: false,
  layoutV1: true,
  layoutV2: false,
  isHeadLess: false,
  filterLocales: false,
  defaultRoute: 'home',
  useLegacy: false, // to use legacy API (src/scripts/sportsbook/model/EventCache.js)
  maintenance: {
    logoHeight: false, // can be set to a number to overide size
    scripts: '', // can be used to put a script tag in maintenance.html see futwin skinFeatures for an example
    description:
      'Sorry, the site is unavailable at the moment. Please check back soon.',
  },
  node: {
    enabled: false,
  },
  external: {
    enabled: false, // enable it if the client is loaded as a third party app
    alwaysUseGoBack: false,
    sendRefreshToken: false,
  },
  dimensions: {
    favsHeightMobile: '100%',
  },
  konnect: {
    customerId: 44,
  },
  headerMinimalView: {
    joinButtonSm: false,
  },
  carousel: {
    slidesToShow: 1,
    slideIndex: 0,
    bottomRightControls: false,
    noSliderControls: false,
    pagingDots: false,
  },
  streaming: {
    betradarEnabled: false,
    sportmarket: false,
    validCountries: [],
    liveStreaming: false,
  },
  retail: {
    enabled: false,
    ticketPayout: true,
    appName: true,
    fastSport: false,
    ticketReprint: false,
    betslipChecker: false,
    bookingCode: false,
    credit: false,
  },
  isEventCodeVisible: false,
  routing: {
    disabledLeftNav: {
      promotions: true,
    },
    base: 'home',
    modalRouteOverrides: [],
  },
  defaults: {
    // set the odds the first time the user visits
    oddsFormat: 'DECIMAL',
    // which odds format we use to calculate the total odds
    baseOddsFormat: undefined,
  },
  odds: {
    canChange: true,
    types: [
      { code: 'DECIMAL', short: 'DEC' },
      { code: 'FRACTIONAL', short: 'FRA' },
      { code: 'AMERICAN', short: 'US' },
    ],
  },
  // antillephone licence
  seal: {
    enabled: false,
    id: '',
    godaddy: false,
    curacao: false,
    url: 'snippet.antillephone.com/apg-seal.js',
    prefix: 'apg',
  },
  welcomePage: {
    // Show a welcome page as the entry to the site
    enabled: false,

    // One of [layoutV1, layoutV2, layoutV3]
    layoutV1: false,
    layoutV2: false,
    layoutV3: false,

    // Redirect user to welcome page on ANY route unless they are logged in
    hasRedirect: false,

    // Shows the register modal inline, allowing user to register without navigating
    // or clicking anywhere
    hasInlineRegisterForm: false,

    // Amount of sequential steps at bottom of page. Needs to match up with whatever is in translations
    steps: 3,

    // Display using session storage
    displayEverytime: false,

    // Only display the welcome page when loading the main page route
    homeRoute: false,

    route: '/welcome',
  },
  highlights: {
    // can be set to a path which will replace the default sport images
    customBg: false,

    // Opacity of the bacground image is skin- and image-specific
    bgOpacity: 0.5,
  },
  asianLayout: {
    // Markets are given a column layout
    enabled: false,
  },
  bettingKings: {
    // 'Betting Kings' widget placeholder data
    usePlaceholderData: false,
  },
  playerIndex: {
    appKey: 'amelco-sportsbook',
  },
  priorityNav: {
    inPlayCounter: false,
    hideMoreButton: false,
    isActiveUnderline: false,
    smallIcons: false,
    icons: {
      v1: true, // image style icons
      v2: false, // grey scale style icons
      v3: false, // grey scale style icons
      v4: false, // white new icons
    },
    menuDivider: false, // display divider(border) between menu items
  },
  password: {
    view: {
      v1: true, // full screen view
      v2: false, // centered modal with controlable background image
    },
    v2Options: {
      // background image sets full screen background image of view V2 style
      backgroundImage1X: '/images/password/password-1x.jpg',
      backgroundImage2X: '/images/password/password-2x.jpg',
      backgroundImage3X: '/images/password/password-3x.jpg',
    },
    resetViaAgent: false,
  },
  globals: {
    borderRadius: '4px !important',
  },
  global: {
    customScrollbar: true,
    fontSize: '90%',
    centerBodyPaddingNone: false,
    // customFontSize: '110%'
    customFontSize: false,
    // paddedBody: '90%'
    paddedBody: false,
    paddedBodySearchbar: false,
    paddedBodyBgColor: false,
    // image goes to /images/bg.jpg
    // sets content max width and leaves a gutter around the site.
    maxWidth: '100%',
    // padded max width makes the header full width with padding around content.
    // for this to work maxWidth has to be set to '100%'
    paddedMaxWidth: false,
    dateFormat: 'DD/MM/YYYY',
    dateTimeFormat: 'D MMM YYYY @ h:mm A',
    timeFormat: 'h:mm A',
    headerDate: false, // only date
    headerDateTime: false, // date and time
    headerLiveClock: false,
    footerDateTime: false,
    // currently it's only implemented in FooterViewType2
    footerTimeZone: false,
    tabs: {
      v1: true,
      v2: false,
    },
  },
  // things on land should occur when the user lands at the site
  onLand: {
    // will block render of loginRegisterCTA and display country blocked modal
    locationBlock: false,
    // on land open a loginRegisterCTA modal
    loginRegisterCTA: false,
  },
  modals: {
    // if logo is passed to modal component this image will be shown
    headerImageURL: false,
    maxHeight: true,
  },
  bundle: {
    defaultLang: false,
    turnOffAutoGenerateSitemap: true,
  },
  teaserBets: {
    historyTextSmallDisplay: false,
  },
  insurance: {
    autoSelectRiskFreeBonus: false,
  },
  betslip: {
    disableEventTime: false,
    singleGroupedBet: {
      v2: false,
    },
    emptyMessageV2: false,
    returnLabelRight: false,
    betslipTabIcons: false,
    selectionOddsIcon: false,
    acceptPriceChange: false,
    mobile: {
      hideBetslipButton: false,
    },
    priceChangeToggleBottom: false,
    mixedRegister: false,
    noJaggedEdge: false,
    // Show empty betslip with header and icon
    noBetsV2: {
      enabled: false,
      icon: '',
    },
    freebet: {
      mixWithRealStake: true, // can be placed together with real stake
    },
    v2: false,
    v3: false,
    // to show various type bets at top or bottom
    typeBetsTop: false,
    keyboardLayout: [
      [0, 1, 2, 3, 4],
      [5, 6, 7, 8, 9],
    ],
    betslipCustomBorder: false,
    slideBreakpoints: 1249,
    showAllMarket: false,
    tabbed: false,
    autoSwitchBetslipMode: false,
    combiBetParlayWarningMsg: false,
    betslipTabs: ['betslip', 'open_bets', 'closed_bets', 'cashout'],
    separatedTabs: false,
    multipleTabFirst: false,
    includeTaxText: true,
    substractTaxOnBetslipConfirmation: false,
    // can be used to set a background image at bottom of betslip.
    backgroundImage: false,
    isLight: false,

    // enables quickbetslip.
    quickBetslip: false,
    // force quickbetslip enabled on mobile devices
    quickBetslipForceMobile: false,
    // limits quickbetslip to mobile devices only
    quickBetslipMobileOnly: false,

    createLogs: true,
    acceptAllPriceChange: false,
    minStake: 0,
    acceptedBetsView: 'accepted',
    maxBtn: true,
    initialLoginView: false,
    bookingCode: {
      enabled: false,
    },

    // when true betslip will be an accordion section in the burger menu.
    inLeftNav: false,
    borderLeft: true,
    defaultStakeButtons: false,
    defaultStakeValues: [],
    defaultStakeCompact: false,
    // show currecny in stake select buttons
    defaultStakeCurrency: true,
    expandButton: false,
    validateBalance: true,
    showLogin: true,
    smallFont: false,
    printBetslip: false,
    fullCoverBets: true,
    showTotalOdds: true,
    showSingleGroupedBet: true,
    loginRequest: false,
    showMaxStake: false,
    showMaxStakeOnSingle: false,
    maxNumberSelections: 14,
    maxBetSelectedAlert: false,
    mobileFloatButton: false,
    multiples: {
      showEstimatedReturns: false,
    },
    // Show clear slip at bottom of betslip
    clearSlipButtonBottom: false,
    // Show clear slip at top of betslip
    clearSlipButtonTop: true,
    // Show headers for grouped views in betslip (Singles, Combi, System)
    multipleGroupedHeader: true,
    // Show betslip type tabs even if no selections added to betslip
    showTypeTabsWhenEmptySel: true,
    // paper tear effect at the top of the bet slip only
    topPaperTearEffect: false,
    // shadow above betslip summary box
    betslipSummaryShadow: true,
    singlesV2: false,
    // Empty betslip summary part
    emptySummary: false,
    // Show login text and open login from in place bet button
    showNotLoggedInBtn: false,
    // clear bets button next to place bet button
    clearBetsButtonInPlaceBet: false,
    groupedV2: false,
    // acceptAllOdds between single bets and grouped
    acceptAllOddsV2: false,
    // one line bets summary above grouped
    summaryV2: false,
    // Show the betslip history modal
    showBetslipHistoryModal: false,
    // in input field, placehorder text shows in topside border
    borderPlaceholder: false,
    // Show the no bets placed model
    noOpenBetsModal: false,
    // Fixed bottom part of betslip
    fixedBottom: false,
    // Fixed bottom part of betslip, only for mobile
    fixedBottomMobileOnly: false,
    // Enable edit button in Betslip V1 mobile view
    betslipEditV1: false,
    // Removes tabs in betslip mobile view
    showBetslipTabsInMobileView: true,
    // Bet details drop down in system bets
    showBetDetailsDropdown: false,
    // Enables cashout on under the active bet tab
    activeTabCashout: false,
    // V2 of the combi bet warning for invalid combi bets
    combiWarningv2: false,
    // betslip placebet text change on suspended sel
    placeBetTextChangeOnSuspendedSel: false,
    // Update the old odds of selections once the betslip is closed
    updateOldOddsOnBetslipClose: false,
    // Save the previous stake for parlay and system bets when adding or removing selections
    saveStake: false,
    showToWinButton: false,
    // Display if a selection becomes unavailable after trying to place a bet
    selectionUnavailable: false,
    cut1Enabled: false,
    // allow to remove the space between teaser bet body and the stake and odds input
    hideTeaserBodySpacer: false,
    // display the accept all odds switch.
    acceptAllOddsSwitcher: false,
    removeSuspendedMarketsButton: false,
    suspendedDesign: false,
  },
  betslipValidator: {
    CyprusRegulation: false,
  },
  cashout: {
    // Enable Partial Cashout
    partialEnabled: false,
    // Enable Auto Cashout
    autoEnabled: false,
  },
  productsNav: {
    enabled: true,
    italic: false,
    bold: false,
    condensed: false,
  },
  scrollableShadows: {
    primaryColour: false,
  },
  scoreBoard: {
    statScore: {
      enabled: false,
      eventPageOnly: true,
      inplayID: 'xxx',
      prematchID: 'xxx',
      stats: false,
    },
    scoreboardCustomBg: false,
    backgroundImage: true,
    betradar: {
      enabled: false,
      stats: false,
      custId: '',
      custName: '',
      customTheme: false,
    },
    oldBetradar: {
      enabled: false,
      customerName: 'amelcosports',
    },
  },
  title: {
    useDynamic: false,
  },
  mobileNavigationTabs: {
    tabs: [],
  },
  homeWrapper: {
    enabled: true,
    promotions: [],
  },
  burgerMenu: {
    leftJapan: false,
    fallbackImg: 'fallback.png',
    burgerHeaderMenu: false,
    v1: true, // default
    v2: false, // japan layout with casino/sports etc
    burgerMenuCustomFormat: false,
    burgerMenuBorderedItems: false,
    burgerMenuNameOpacity: false, // burgerMenuNameOpacity: '0.6',
    burgerMenuItemBg: false,
    // show today / tomorrow links
    todayTomorrow: false,
    backgroundMargin: false,
    enabled: true,
    liveTree: true,
    favourites: true,
    classicTree: false,
    sportTreeAtHome: true, // whether to show sport tree when it's home page
    showSelectedSport: false,
    favouriteSports: {
      maxSports: false,
      virtualsLink: false,
    },
    // if this is set to true the stats icon will be displayed in the left sidebar
    showStats: false,
    logoEnabled: false,
    // Burger menu sorting configuration
    // '' -> alphabetically
    // 'weighting' -> using weighting
    // 'mixed' -> using weighting and alphabetical sort together.
    sorting: {
      sport: 'mixed',
      category: 'mixed',
      competition: 'mixed',
    },
    extraSessionProducts: [],
    additionalMenu: false,
    additionalMenuItems: [],
  },
  hideHomeHighlightIfSportsbookProductActive: false,
  allAvailableProducts: [
    {
      name: 'sportsbook',
      belongsTo: 'SPORTSBOOK',
    },
    {
      name: 'live_betting',
      belongsTo: 'SPORTSBOOK',
    },
    {
      name: 'virtuals',
      belongsTo: 'SPORTSBOOK',
    },
    {
      name: 'arena',
      belongsTo: 'SPORTSBOOK',
    },
    {
      name: 'casino',
      belongsTo: 'SPORTSBOOK',
    },
    {
      name: 'live_casino',
      belongsTo: 'SPORTSBOOK',
    },
    {
      name: 'financials',
      belongsTo: 'SPORTSBOOK',
    },
    {
      name: 'promotions',
      belongsTo: 'SPORTSBOOK',
    },
  ],
  searchAndSettings: {
    showLangSelector: true,
    searchField: true,
    enabled: true,
  },
  betHistory: {
    columns: {
      betSlipId: true,
      slipId: false,
    },
    rows: {
      noMatchLabel: false,
    },
  },
  transactionsHistory: {
    // whether to show it as tabular (more like a spreadsheet) view OR slip list view
    v2: false,
    filters: ['all', 'deposit', 'withdrawal', 'bets', 'bonus'],
    useCredits: false,
  },
  analytics: {
    otherLevels: false,
  },
  header: {
    accountButtonV2: false,
    minimalSearchBar: true,
    noIconButton: false,
    headerUserButtonDeposit: false,
    icons: {
      nbaLogo: false,
    },
    loginHeader: false,
    withdrawalsIcon: true,
    // various header types currently available ['default', 'thin', 'tabbed', 'centered', 'japan'].
    headerGradeintBg: false,
    forgotPassLink: true,
    headerBgImageGradient: false,
    headerBg: false,
    headerButtonsV2: false,
    headerGradeintBgNoLogoStroke: false,
    type: 'default',
    thinLogoContainer: false,
    boldFontsMenu: false,
    // displays the lang selector in the header
    showLangSelector: false,
    showLogin: true,
    joinNowButton: false,
    userDropdownButton: true,

    buttonLikeAccLinks: false,
    oddsChangebetHeaderType: false,
    showLogin: true,

    userMenuTypeV1: true,
    userMenuTypeV3: false,
    userMenu: {
      hideBetslipSettings: true,
    },

    smallLeftPadding: false,

    // allows configuring header balance to either show just cash balance, or cash and bonus
    // Options: total, cash, accountBalance (balance amount) or bonus (Default = total) https://jira.amelco.co.uk/browse/WLS-1743
    balanceType: 'total',
    showTotalForBonus: true,
    // secondary links on the right hand side only useable on type thin see above.
    // item structure
    // {
    //  "name": Slots, will be run through {App.Intl(`top_nav.links.${link.name}.label`)}
    //  so needs to be in relevant translations.
    //  "url": '/casino/slots',
    //  "active": will be evaluated for true alongside testing if route is active by default
    //  so setting to true means link is always in active state
    // }
    secondaryLinks: [],
    additionalProducts: [],

    // activeates a second logo next to main logo
    secondLogo: false,

    // when set to false the site runs with no header.
    displayed: true,
    helpCenterURL: false,
    minimal: {
      v1: true,
      v2: false,
      headerMenu: false,
      depositButton: true,
      // centres the logo in mobile header.
      logoCentred: true,

      // There are different designs for the account menu on mobile.
      secondaryAccountMenuDropdown: false,
      searchField: true,
      searchExpanded: true,
      tinyLogoXxsViewports: true,
      // whether to show date time when on minimal view OR not.
      dateTime: false,
      languageSelector: true,
      backButton: false,
    },
    mobileOnly: false,
    overlapping: false,

    // when set to false the site runs with no logo
    logo: true,

    // Large size logo
    bigLogo: false,

    // when set to false balance will not appear in the header
    showBalance: true,
    showBalanceMobileOnly: false,

    // show logout option in user dropdown
    showLogout: true,

    showRegister: true,

    showDeposit: true,

    // Products Nav in header rather than sidebar
    productsNavMobile: true,

    sportMenu: {
      active: false,
      fierce: false, // sportMenu to be activated skipping all conditions.
      showInPlay: false,
      nonSportItemIcon: true,
      noIcon: false,
      gradientBorder: false,
      casino: false,
    },
    showLastLogin: false,

    showSearch: true,

    pamHeaderSecondary: false,

    // Use PoEditor string header.custom_register
    customRegisterText: true,
  },
  languageSelector: {
    flagIcons: true,
  },
  register: {
    informativeText: false,
    customTandC: false,
    simplifiedTAndC: false,
    citizenInstructions: false,
    termsAcceptedByDefault: false,
    occupationOpt: false,
    successPage: {
      v1: true,
      v2: false,
      promoButton: true,
    },
    showCurrency: true,
    hideTermsLabelRequired: false,
    prohibitedFormUrl: '',
    passwordStrengthMeter: true,
    quickregister: false,
    hiddenFields: [],
    phone: {
      dialCode: false,
      allowDropdown: true,
      startWithUniqueNumber: false,
    },
    useLocalTimeForDOB: false,
  },
  promotions: {
    leftNav: false,
    topTabs: false,
    mirroredLabel: false,
    categories: [],
    secondLinkOnOverlay: false,
    openCTAInModal: false,
    disabledLeftNav: true,
  },
  casino: {
    mainFolder: 'rgs',
    searchButtonV2: false,
    demo_and_login_btn: false,
    favButton: false,
    providerSelect: true,
    nameBar: true,
    enabled: true,
    casinoHeaderDisble: false,
    casinoImageHeight: false,
    v1: false,
    v2: true,
    v3: false,
    topNav: true,
    inlineSearch: true,
    provider: 'RGS',
    customerName: 'amelco',
    fullWidth: false,
    leftMenu: false,
    verticalButtons: false,
    casinoSearchV2Enabled: false,
    // Different layout for casinosearchV2
    casinoSearchV2LayoutV2: false,
    // weather to show Play Demo button on games
    demoPlayBtn: true,
    searchInput: true,
    nameBar: {
      disableHover: false, // disable namebar jumping on hover, used for V3
      mobile: {
        disabled: false, // option to remove namebar on mobile
      },
    },
    balances: false,
    modal: {
      loadingImage: false,
    },
    // Not sure this is still used
    imageHeight: {
      default: 220,
    },

    ratios: {
      default: { SOLID: 150 / 200, HUB88: 307 / 406, BOOMING: 300 / 400 },
      liveCasino: {},
    },

    casinoImageRatio: false,
    categories: [
      {
        category: 'featured',
        label: 'casino.featured',
        link: '/casino',
        svg: '/images/icons/casino/casino_featured.svg',
      },
      {
        category: 'popular',
        label: 'casino.popular',
        link: '/casino/popular',
        svg: '/images/icons/casino/casino_cherry.svg',
      },
      {
        label: 'casino.favourites',
        link: '/casino/favourites',
        svg: '/images/icons/casino/casino_favourites.svg',
      },
      {
        category: 'slot',
        label: 'casino.slots',
        link: '/casino/slots',
        svg: '/images/icons/casino/casino_slots.svg',
      },
      {
        category: 'roulette',
        label: 'casino.roulette',
        link: '/casino/roulette',
        svg: '/images/icons/casino/casino_roulette.svg',
      },
      {
        category: 'blackjack',
        label: 'casino.blackjack',
        link: '/casino/blackjack',
        svg: '/images/icons/casino/casino_blackjack.svg',
      },
      {
        category: 'poker',
        label: 'casino.poker',
        link: '/casino/poker',
        svg: '/images/icons/casino/casino_poker.svg',
      },
      {
        category: 'bingo',
        label: 'casino.bingo',
        link: '/casino/bingo',
        svg: '/images/icons/casino/casino_bingo.svg',
      },
    ],
    descriptionIsGameName: false,
    casinoMenu: {
      leftrow1: '',
      leftrow2: '',
      carousel: [],
      rightrow1: '',
      rightrow2: '',
    },
  },
  account: {
    // array of balance parts. Example: ['DEPOSIT', 'CREDIT', ...]
    displayedBalanceParts: undefined,
    // display cash balance as total in the client
    cashBalanceAsTotal: false,
    isFieldRequired: [],
    keepEditableAlways: [],
    keepDisabledAlways: [],
    mirroredLabel: true,
    pageTitles: true,
    disableModalClose: false,
    balances: {
      v1: true,
    },
    betFilters: ['ALL', 'OPEN', 'WIN', 'LOSE', 'CLOSED'],
    accountJpn: false,
    hideVerificaction: false,
    hideDocType: false,
    hideDeactivate: false,
    hideMenu: false,
    // to show username instead of name + surname
    showPunterUsername: false,
    /* Terms and Conditions Update popup */
    tandcPopup: false,
    tandcServerTerms: true,
    tandcPopupLogoutButton: true,
    tandcPopupURL: 'termsandconditions',
    /* account section opens in a modal */
    modal: true,
    burger: true,
    accountExclusions: {
      showTEL: false,
      showTELOnlyOnExclusionPage: false,
      mandatory: true,
      hideDepositOnTimeout: false,
      hideChangePasswordOnTimeout: false,
    },
    standAlone: {
      enabled: false,
      v1: true,
      v2: false,
    },
    canEdit: false,
    // displays a logout link in the left nav of account menu.
    showLogout: true,
    modalCloseIconLeft: false,
    menu: {
      // when set to a path displays bullet icons '/images/icons/right-arrow.svg'
      icons: false,
    },
    myDetails: {
      isDisabledWhenVerified: false,
      allowUnknownGenderChange: true,
      required: {
        house_number: true,
      },
      disabled: {
        contact_details_title: true,
        username: true,
        name: true,
        DOB: true,
        gender: true,
        country: true,
        state: true,
        address: true,
        nationalId: true,
        email: true,
        mobile: false,
        ssnLast4Digits: true,
      },
      displayed: {
        username: true,
        email: true,
        firstName: true,
        surname: true,
        DOB: true,
        gender: true,
        nationalId: true,
        rewardCardNumber: false,
        countries: true,
        states: true,
        postCode: true,
        houseNo: true,
        street: true,
        city: true,
        mobile: true,
        telephone: true,
        bonusCode: false,
        saveButton: true,
      },
    },
    myBets: {
      // settign to false removes left nav on /mybets route modal unaffected
      leftNav: true,
      v2: false,
      confirmCashout: false,
    },
    withdrawal: {
      typeLogos: {},
      skrillNetellerLogo: false,
      useLimits: true,
      confirmRequired: true,
      checkEmail: false,
      overideEmailVerification: false,
      showPending: false,
      blockAddPaymentOption: [],
      iFramePaymentMethod: [],
      useFullValidation: ['rcards_apco'],
      displayPaymentOptions: true,
      getPaymentParamsFromServer: true,
      info: {
        reservedDeposit: true,
      },

      // setting to an numeric value will show the user the fee when they attempt to withdraw
      // in the currency of the site this feature is intended only for bitcoin based sportsbooks currently
      // it will also not work properly on mulicurency based sportsbooks.
      withdrawalFee: false,
      v2: false,
      v3: false,
      canCancel: true,
      showWelcomeMessage: true, // to show the welcome meaasage
      showStatusMessage: true, // to show the status meaasage
      skipAccountValidation: false,
      successMessageV2: false,
      pendingCancelButton: true,
      documentsUploadLink: 'account/documents',
      successMessageV3: false,
      paymentOptionsV2: false,
      withdrawalInputV2: false,
      disableNewCardOption: false, // to allow withdrawal using new card
      allowPending: false, // set true to allow further withdrawals when some previous ones are being processed already
      filteredMethods: [],
    },
    documentUpload: {
      removeExpiryDate: false,
      showForm: false,
    },
    registration: {
      success: {
        preConfirmedUser: false,
      },
      displayFieldLabels: false,
      validateZip: false,
      validLowerCaseEmail: true,
      labelField: [],
    },
    deposit: {
      favStar: true,
      shortDepositInput: false,
      confirmRequired: true,
      canUseOffsiteDeposit: false,
      gateway: '',
      defaultPaymentMethod: '',
      paymentMethodInfo: false,
      layoutV1: true,
      layoutV2: false,
      layoutV3VerticalLayout: false,
      paymentMethods: [],
      canCancel: true,
      useLimits: true,
      canToggleBonusCode: true,
      hidePaymentGatewaySelect: false,
      showHeader: false,
      promoCodeCheckboxValue: true,
      hasPaymentMethodsInfo: false,
      alwaysShowMethodIcon: false,
      paysafeDescriptions: {
        v1: true,
        hideSkrill: true,
      },
      toggleCheckboxOnBonusDelete: false,
      useDefaultMethodWithGatewaySelect: true,
      hideOfflineBank: false,
    },
    depositAmount: {
      gateways: {
        images: {
          // v1 uses color payment gateway images and applies a css greyscale filter.
          // when active filter is removed and images has color again.
          v1: true,
          // v2 uses payement images from "/payments/v2" an images with both name-black.png and name-grey.png
          // must be present. Active gateway will be displayed in black.
          v2: false,
        },
      },
    },
    depositConfirmation: {
      view: {
        v1: true,
        v2: false,
        v3: false,
      },
    },
    depositFailure: {
      view: {
        v1: true,
        v2: false,
      },
    },
    disabledSections: [],
    idTypes: [],
    geolocationRestriction: false, // if geolocation restrictions are in place for deposits or betting
    quickAmounts: false,
    settings: {
      selfExclusionView: true,
      selfExclusionViewFilters: [
        'Deposit Limit',
        'Stake Limit',
        'Session Limit',
        'Casino Reality Check',
        'Timeouts',
      ],
      // use the string values that the view can convert eg '12H' '72h' '2M' or false to allow all values
      minimumTimeout: false,
      timeoutOptions: ['24H', '3D', '7D', '1M', '6M'],
      deactivationOptions: [
        '6months',
        '1year',
        '2years',
        '3years',
        '5years',
        'permanent',
      ],
      deactivationNewLogic: false,
    },
    splitBonusApplicationType: false,
    deactivation: {
      disclaimer: false,
      myAccount: {
        header: false,
        checkbox: false,
      },
      acknowledgment: {
        header: false,
        checkbox: false,
      },
      wavierAndRelease: {
        header: false,
        checkbox: false,
      },
    },
  },
  bonus: {
    cancel: true,
    expirationDate: true,
    daysToFulfill: false,
    description: false,
    // confirmation popup
    cancelConfirmation: false,
    bonusViewTabs: ['active', 'available', 'historic'],
    useExpiryOverridesForActive: false,
    BonusesViewv2: false,
    FreeBetTokenV2: false,
    freeBetDropdownV2: false,
    // Display free bet tokens in bonus
    freeBetTokenModal: false,
  },
  morph: {
    enabled: false,
  },
  zopim: {
    enabled: false,
  },
  registration: {
    // Legacy registration server version
    sv1: false,
    v1: true,
    v2: false,
    /* display registartion in a modal rather than navigating to route */
    modal: true,
    /* shoudld register route be wrapped in PageWrapper */
    pageWrapper: false,
    /* existing users link pinned to bottom of registration rather than under main header */
    fixExistingUsers: false,
    /* shows all steps on one page, based on the GMG design */
    singlePage: false,
    multiPage: false,
    useOrdering: false,
    enableAutoCompletePostcode: false,
    noEmptyCity: false,
    /* allow autodoc integration */
    idCheck: {
      autoDocUrl:
        'https://www.verifymyid.com/adhf/Home/Redirect?tokenId={tokenId}',
    },
    personalValidation: {
      postcode: true,
    },
    currencyDisabled: false,
    buildRegistrationMsg: {
      region: false,
      county: false,
      kycAdditional: false,
    },
    listOfCountries: {
      US: { states: [], defaultState: '' },
    },
    streetHasHouseNo: false,
    dynamicPhoneNumberPlaceholder: true,
    fieldsWithDisclaimer: [],
  },
  eventPage: {
    scoreBoardBg: false,
    viewToggle: true,
    marketColumns: 1,
    marketHeaderSmallStyle: false,
    marketGroups: {
      favourites: true,
      highlighted: {
        enabled: false,
        group: '',
        sports: [],
      },
      // Priority order of tabs to be in front
      tabsInFront: [],
    },
    buildABet: {
      enabled: false,
      v2: false,
      oddsInside: false,
      removeButton: false,
    },
  },
  sports: {
    competitions: {
      useCMSLayout: false,
    },
    swapParticipants: false,
    pitchers: {
      enabled: false,
    },
  },
  events: {
    pitchers: {
      useBrackets: false,
      // only load baseball events with pitchers
      required: false,
    },
    noScoreSports: [],
    // when events are grouped by date this specifies how many should be open by default
    // expects a number or 'all'
    datesToExpand: 'all',
    alwaysShowLiveTabs: true,
    // used to show the total number of events for that competition with ignoring the filters on that specific widget
    showCompTotal: false,
    // show filter tabs for horseracing
    horseRacingFilter: true,
    // show today tab for horseracing
    showTodayTabHorseRacing: false,
    // show all races under next3 tab
    showAllUnderNext3: true,
    // compact view of info box
    compactInfoBox: false,
    // show league name where competitions gruouped by day
    showCompNameOnDayGroups: false,
    collapseCompContainer: true,
    showCompEventCount: true,
    showCompSubTitle: true,
    keyMarketsShownNumberBreakpoints: [
      { from: false, to: 991, show: 2 },
      { from: 918, to: 1024, show: 3 },
      { from: 1249, to: 1600, show: 2 },
      { from: 1600, to: false, show: 3 },
    ],
  },
  countryView: {
    // expects a number or false which will open all markets by default.
    colapseAllMarketsAfterIndex: false,
  },
  racingSportsView: {
    // used to change the RacingCompetitonView, RaceCard, RacingEventsView, etc..
    v2: false,
  },
  outrights: {
    // expects a number or false which will open all outrights by default.
    colapseOurightAfterIndex: 3,
    oneColumnTable: false,
    horizontal: false,
  },
  competitions: {
    logos: {},
  },
  markets: {
    scores: {
      shortLabels: false,
    },
    inPlayTime: true,
    backLayNumber: false,
    hideScore: false,
    liveScoreV2: false,
    scoreNextToTeam: false,
    moreSportsV2: false,
    americanLayout: ['AMERICAN_FOOTBALL'],
    alternativeLines: false,
    textTransform: true,
    header: {
      showMarketName: true,
      showColPosition: true,
      autoHeaderWidth: false,
      columnHeaderName: false,
      gradientBg: false,
    },
    selections: {
      retrieveSelectionCode: false,
    },
    favourites: true,
    stats: false,
    vsStyle: false,
    showVs: true,
    inplayColumnSplit: false,
    expandedNum: 0,
    expandable: false,
    // when set to false, Competitions page will not display market selector above displayed events
    marketSelector: false,
    mobileVertical: false,
    verticalTeamNames: {
      enabled: false,
    },
    verticalMarketsDisplaySports: false,
    // Use different display position for market number depending on Vertical or Horizontal markets.
    differentPositionDisplayMarketNumber: false,
    reversMLKeymarkets: false,
    /**
     * Include league names in uppercase that should show logos for participants eg.: 'SUPER LEAGUE',
     * if you want to use club logos for new league, create path :
     * /images/{league_name}/clubs/{club_name}.png
     *  */
    showClubLogo: [],
    showNflLogos: true,
    keyMarketOrders: {},
    eventFavourites: true,
    usePeriod: false,
    leftFlags: false,
    backLay: {
      enabled: false,

      // Stylistic differences
      v1: false,
      v2: false,
      v3: false,
    },
    displaySelectionNames: false,
    hideMarketNum: false,
    hideMarketNumMore: false,
    // only one key market shown, and partcipant names are shown on selections. Requires main keymarket to be win related
    simpleMarketLayout: false,
    multiView: false,
    // will display number of markets available
    numberOfMarkets: false,
    european: {
      mobile: {
        markNumOnLeft: false,
        markNumBottom: false,
      },
      desktop: {
        markNumBottom: false,
        markNumOnLeft: true,
      },
    },
    boldFonts: false,
    abbreviations: {
      A: '1',
      B: '2',
      D: 'x',
      DRAW: 'x',
      AH: 'H',
      BH: 'A',
      AX: '1X',
      XB: 'X2',
      AB: '12',
      AHCP_MOVING: 'SPREAD',
    },
  },
  footer: {
    footerLogoImage: false,
    paymentMiniLogoList: false,
    curachaoV2: false,
    optionalDescription: false,
    appDownloadLogos: false, // footertype6
    showParagraph: true,
    showPagcorLogo: true, // footertype6
    showTelegramLogo: true, // footertype6
    showGamecareLogo: true, // footertype6
    responsible_gamingLogo: '18+2.png', // footertype6
    showHr: true, // footertype6
    paymentLogosName: false,
    age18: false,
    alternativeCopyright: false,
    sessionTimeNextToDate: false,
    // swaps the footer view out must be an integer so far 1/2
    type: 1,
    footerType1CustomText: false,
    backToTop: true,
    // type4 parameters
    paymentProvider: [], // paymentProvider: ['logo1', 'logo2'] (logos goes to images/logos)
    footerLogos: [], // footerLogos: ['logo1;logo1-url', 'logo2;logo2-url'] (logos goes to images/logos)
    supportEmail: [], // supportEmail['example@example.com']
    showServerTime: false,

    // **** start of link block for type 1 footer
    disabled: false,
    links: {
      disabled: false,
      contact: true,
      tandc: true,
      game_rules: true,
      anti_money_laundering_policy: false,
      payment_methods: false,
      results: true,
      policy: false,
      user_policy: false,
      statisticsURL: false,
      about_us: false,
      responsible_gaming: false,
      responsible_gambling: false,
      faq: false,
    },
    USFixtures: {
      categoryIds: [],
    },
    helpLinks: [
      {
        route: 'https://Tawk.to',
        translation: 'footer.live_chat.title',
      },
      {
        route: '/faq',
        translation: 'footer.faq_help.title',
      },
      {
        route: '/gamerules',
        translation: 'footer.game_rules',
      },
      {
        route: '/amlpolicy',
        translation: 'footer.contact',
      },
      {
        route: '/promotions',
        translation: 'footer.promotions',
      },
      {
        route: '/amlpolicy',
        translation: 'footer.aml_policy',
      },
    ],
    aboutUsLinks: [
      {
        route: '/aboutus',
        translation: 'footer.about_us',
      },
      {
        route: '/termsandconditions',
        translation: 'footer.t&c',
      },
      {
        route: '/responsible-gaming',
        translation: 'footer.responsible_gaming',
      },
      {
        route: '/userpolicy',
        translation: 'footer.user_policy',
      },
      {
        route: '/privacypolicy',
        translation: 'footer.privacy_policy',
      },
    ],
    curachaoLogo: false,
    // **** end of link block for type 1 footer

    // **** start of link block for type 2 footer
    // links must have a route and translation key
    // {
    //    route: '/help',
    //    translation: 'footer.help',
    // }

    // **** end of link block for type 2 footer

    // show seesion duration only available on footer type 2
    sessionTime: false,

    oddsDropdown: true,

    // shows the images of the payment methods active on the site.
    paymentMethodImages: true,
    // shows the images of the payment links
    paymentLinkImages: true,
    // sets the styles of the payment logos if the above is true.
    // options are 'normal', 'circular', 'greyscale'
    paymentLogosStyle: 'circular',
    // aditional logos can be added here they must exist in the same folder as the payment logos '/images/payments/payment-${name}.png'.
    paymentLogos: [],

    paymentLogosType: 'png',

    // adds a row of brand logo's ussuall payment gateways.
    // they must be saved in the images/logos/ directory as .pngs
    secondaryLogos: [],

    // add key value pair to get social icon to display.
    social: [
      // {
      //  name: 'facebook',
      //  url: 'www.facebook.com',
      // },
    ],
    dotsBetweenLinks: false,
    bottomOfPage: false,
    curacaoV2: false,
    curacaoId: '',
    curacaoUrl: '',
    liveClock: true,
  },
  forms: {
    /*  label type
     *  'slide':  replaces placeholder and slides up onFocus
     *  'hide': reveals itself onInput as placeholder disapears

     */
    labelType: 'slide',

    //  'border' : places the label on the top border
    borderLabel: false,
    /* show asterix for required inputs */
    markRequired: false,
    // decrease the font size when selecting the slide
    labelDecreaseFontSize: false,
  },
  buttons: {
    gradientBg: false,
    gradientBgAlt: false,
    V2: false,
    V2GlobalStyle: '',
  },
  registerCta: {
    // adds a register cta at the bottom of the betslip
    enabled: true,
    // offset push's it up from the bottom in px to allow for live chat widgets.
    offset: 0,
    // custom link
    link: '/register',
  },
  loginView: {
    registerLink: false,
  },
  login: {
    modal: true,
    v2: false,
    clickOutSideClose: true,
    selfLimitCall: false,
    saveInfo: {
      password: true,
    },
  },
  selectionView: {
    triangleArrow: false,
    border: false,
    borderedBlocks: false,
    // How price changes are displayed in the selection
    // TOP/BOTTOM -> displays above and bellow price
    // LEFT -> displays on the left side
    changeDisplay: 'TOP/BOTTOM',
    // Change text color in price block according to price change
    oddsChangeColorUpdate: false,
    // price block design for american skins
    priceBlockV2: false,
    priceBlock: {
      inlineLine: false,
    },
    overwritePriceBlockColor: false,
    noPaddingCell: false,
    emptySameBackgroudAsSuspended: false,
    suspendedSelOddsEmpty: false,
    showSuspendedPrice: true,
    suspendedSelPriceLockIcon: false,
    suspendedWithLockAndNumber: false,
    suspendedwithDisabledBackground: true,
  },
  silks: {
    showEmpty: true,
  },
  regEx: {
    // Matches any alpha(s)  within a string i.e is a char present
    // Used in:
    // Validator.js -> validPassword
    // PasswordField.js -> Letters check
    // InputWithIconBox -> tooltipCheck2
    alphas: /[a-zA-Z]/,
    // Matches Alpha, numbers and space, no special characters
    // Used in:
    // Validator.js -> validEmail
    alphaNumeric: /^[0-9a-zA-Z\u00c0-\u017e \s]+$/i,
    // Username, alpha numeric with space without leading and trailing spaces
    // Used in:
    // Validators.js -> postCode,birthName, documentID, ID issuer
    // MyDetailsView -> postCode
    alphaNumeric2: /^[0-9a-zA-Z\u00c0-\u017e]+(\s+[0-9a-zA-Z\u00c0-\u017e]+)*$/i,
    // street, alpha numeric with space without leading and trailing spaces. Comma, dot, hyphen and single quote accepted
    // Used in:
    // Validators.js -> state,colony,houseNo,region,county
    // MyDetailsView -> street, colony, state, houseNo, city, placeOfBirth
    alphaNumeric3: /^[0-9a-zA-Z\u00c0-\u017e,\-'.]+(\s+[0-9a-zA-Z\u00c0-\u017e,\-'.]+)*$/i,
    // Username, alpha numeric with space and '-' without leading and trailing spaces
    // Used in:
    // Validators.js -> name, fathersName
    alphaNumeric4: /^[-0-9a-zA-Z\u00c0-\u017e"]+(\s+[-0-9a-zA-Z\u00c0-\u017e"]+)*$/i,
    // address, alpha numeric with space without leading and trailing spaces. Comma, dot, hyphen, single quote,colon,hash,amp,slash and braces accepted
    // Used in:
    // AddressFormPM -> address field
    alphaNumeric5: /^[0-9a-zA-Z,\-'.()/\\:#&]+(\s+[0-9a-zA-Z,\-'.()/\\:#&]+)*$/i,
    // Username, alpha numeric with space and international characters
    // Used in:
    // Validator.js -> validUserName
    // AppValidators.js -> userNameVal
    // Validators.js -> username
    username: /^[0-9a-zA-Z._]$/,
    usernameEmail: /^[0-9A-Za-z._@\-\+]*$/,
    // Matches only alphabets upper and lower case with spaces
    // Used in:
    // Validators.js -> forename, middlename, surename, street, city
    alphabetsWithSpaces: /^[a-zA-Zа-яА-ЯЁё s]*$/,
    //  To check a password between 8 to 20 characters which contain at least one numeric digit, and one lowercase letter
    // Used in:
    // Validators.js -> password, confirmPassword
    // NewPasswordPM.js -> password, confirm
    // AppValidators -> password
    // changePasswordPM -> password, newPassword
    password2: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/,
    // Matches a valid email address (e.g. mail@example.com)
    // Used in:
    // Validator.js -> validEmail
    // AppValidators -> email
    email: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    nationalId: /^.{6,}$/,
  },
  chat: {
    // used to target chat widget and show / hide it when its visibilty will affect site components.
    query: false,

    // handle chat widget load internaly. will require relevant Chat store changes for that provider.
    // including the config for the particular provider bellow
    // this is instead of loading the chat in GTM dont do both
    loaderEnabled: false,
    intercom: {
      enabled: false,
      id: false,
    },
    tawk: {
      enabled: false,
      if: false,
    },
    freshChat: {
      enabled: false,
      token: '',
    },
  },
  arena: {
    tournamentChat: false,
    sendBirdAppId: '',
    menu: {
      publicTournaments: true,
      privateTournaments: true,
      userProfile: true,
      howToPlay: false,
    },
  },
  social: {
    instagram: {
      clientId: '',
    },
    enabled: false,
    showChatBar: false,
    enableSocialHeader: false,
    overViewBalancesContainer: false,
  },
  topToBottomBgGradient: false,
  currency: {
    noSymbol: false,
    presetValues: {
      default: [1, 100, 500, 1000],
    },
  },
  geocomply: {
    enabled: false,
    installerID: 'an5n9SxWNF',
  },
  xpoint: {
    enabled: false,
    client: 'amelco',
  },
  alternativeStylesV2: false,
  liveCalendar: {
    v2: false,
  },
  widgetBase: {
    // Shows icon for sports in header
    headerIcon: false,
  },
  sportWidget: {
    widgetHeader: false,
    collapsable: false,
    // View switch on live page
    viewSwitch: true,
  },
  sportsWidgetBase: {
    viewSwitch: true,
    keepActiveSport: false,
  },
  forgotPassword: {
    contact: {
      email: '',
    },
  },
  sportMenuWidget: {
    v1: true,
    v2: false,
    // Use button instead of scrollbar to scroll in sportsmenu
    useButton: false,
    // Display highlighted leagues in sport menu
    highlights: false,
    eventDayFilter: false,
    // Display only icon e.g. ['NASCAR']
    onlyDisplayIcon: [],
  },
  mobile: {
    bottomMenu: {
      enabled: false,
      items: ['menu', 'deposit', 'betslip', 'register', 'mybets', 'chat'],
    },
  },
  customLoadingSpinner: false,
  atsUi: {
    tabs: false,
  },
  NumericalInput: {
    type: 'text',
  },
  zendesk: {
    autoFillForm: false,
  },
  oddsChangePopup: false,
  depositBonus: true,
  segment: {
    analytics: false,
  },
  widgets: {
    promotion: {
      fallbackToDesktopImage: true,
    },
    tree: {
      v1: true,
      v2: false,
    },
  },
  defaultAvatarImageName: [
    {
      avatarbasketball2:
        '/images/avatars/defaultAvatars/Avatar_Basketball_2_20220201123010.png',
    },
    {
      avatarbasketball:
        '/images/avatars/defaultAvatars/Avatar_Basketball_20220201123011.png',
    },
    {
      avatarcricket:
        '/images/avatars/defaultAvatars/Avatar_Cricket_20220201123012.png',
    },
    {
      avatardarts:
        '/images/avatars/defaultAvatars/Avatar_Darts_20220201123013.png',
    },
    {
      avatarfootball1:
        '/images/avatars/defaultAvatars/Avatar_Football_1_20220201123014.png',
    },
    {
      avatarfootball2:
        '/images/avatars/defaultAvatars/Avatar_Football_2_20220201123015.png',
    },
    {
      avatargolf:
        '/images/avatars/defaultAvatars/Avatar_Golf_20220201123016.png',
    },
    {
      avatarhorse2:
        '/images/avatars/defaultAvatars/Avatar_Horse_2_20220201123017.png',
    },
    {
      avatartennis2:
        '/images/avatars/defaultAvatars/Avatar_Tennis_v2_20220201123018.png',
    },
    {
      basketball2:
        '/images/avatars/defaultAvatars/Basketball2_20220201123019.png',
    },
    { boxing2: '/images/avatars/defaultAvatars/Boxing_2_20220201123020.png' },
    { boxing: '/images/avatars/defaultAvatars/Boxing_20220201123021.png' },
    {
      football3: '/images/avatars/defaultAvatars/Football_3_20220201123022.png',
    },
    {
      football4: '/images/avatars/defaultAvatars/Football_4_20220201123023.png',
    },
    { football: '/images/avatars/defaultAvatars/Football_20220201123024.png' },
    { golf2: '/images/avatars/defaultAvatars/Golf_2_20220201123025.png' },
    {
      horseracing3:
        '/images/avatars/defaultAvatars/Horse_Racing_3_20220201123026.png',
    },
    { nfl: '/images/avatars/defaultAvatars/NFL_20220201123027.png' },
    { tennis3: '/images/avatars/defaultAvatars/Tennis3_20220201123028.png' },
  ],

  isMobileSingleScoreboard: false,
  spinwheelRewardsIds: [],
};

export default defaults;
