import Cookies from 'js-cookie';
import { action } from 'mobx';

class CookieStore {
  @action
  set = (name, value, options = {}) => {
    Cookies.set(name, value, {
      ...options,
      secure: true,
    });
  };
  @action
  remove = name => {
    Cookies.remove(name);
  };

  @action
  get = name => {
    Cookies.get(name);
  };

  @action
  getJSON = name => {
    Cookies.getJSON(name);
  };
}

const store = new CookieStore();
export default store;
