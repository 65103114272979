import dateFns from './dateFns';

import {
  enUS,
  eo,
  ru,
  pt,
  de,
  tr,
  es,
  bg,
  el,
  ar,
  it,
  fa,
  sv,
  fi,
  no,
  se,
  ir,
  fr,
  zhCN,
} from 'date-fns/locale';

import ptBR from './components/pt-BR';

const getLocale = (locale = App.Globals.lang) => {
  // https://github.com/Adphorus/react-date-range/blob/next/src/locale/index.js
  if (locale === 'es-mx') locale = 'es';

  const locales = {
    enUS,
    eo,
    ru,
    pt,
    de,
    tr,
    es,
    bg,
    el,
    ar,
    it,
    fa,
    sv,
    fi,
    no,
    se,
    ir,
    fr,
    'pt-br': ptBR,
    'zh-cn': zhCN,
  };
  let i18n;
  i18n = locales[locale];
  if (!i18n) {
    i18n = enUS;
  }
  return i18n;
};

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const toLocaleDateString = (
  date,
  options = { year: 'numeric', month: 'numeric', day: 'numeric' },
  locale = App.Globals.lang,
) => date.toLocaleDateString(locale, options);

const format = (date, formatStyle, locale) => {
  const withZone = App.Features('global.zone');
  if (withZone && !formatStyle?.toLowerCase()?.includes('l')) {
    date = new Date(date).toLocaleString(App.Globals.lang, {
      timeZone: withZone,
    });
  }
  if (formatStyle?.toLowerCase()?.includes('l')) {
    let options = {
      ...(withZone && { timeZone: withZone }),
    };
    if (formatStyle === 'LLL' || formatStyle === 'lll') {
      options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        ...(withZone && { timeZone: withZone }),
      };
    }
    if (formatStyle === 'LL' || formatStyle === 'll') {
      options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        ...(withZone && { timeZone: withZone }),
      };
    }
    return toLocaleDateString(date, options);
  }
  if (formatStyle === 's') {
    // 2019-02-03T00:00:00
    return dateFns.format(date, 'YYYY-MM-DDTHH:MM:SS');
  }
  if (formatStyle === 'm') {
    // 2019-02-03T00:00
    return dateFns.format(date, 'YYYY-MM-DDTHH:MM');
  }
  if (formatStyle === 'zh') {
    // 2月5日 month day time
    return toLocaleDateString(date, {
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      hour12: false,
      minute: 'numeric',
    });
  }
  if (formatStyle === 'dzh') {
    // 2月5日 month day
    return toLocaleDateString(date, {
      month: 'long',
      day: 'numeric',
    });
  }

  return dateFns.format(date, formatStyle, {
    locale: getLocale(locale),
  });
};

const formatDistance = (date1, date2, options, locale) =>
  dateFns.formatDistance(date1, date2, {
    ...options,
    locale: getLocale(locale),
  });

const formatDistanceStrict = (date1, date2, options, locale) =>
  dateFns.formatDistanceStrict(date1, date2, {
    ...options,
    locale: getLocale(locale),
  });

const getMonths = (monthShortNames = false) => {
  return months.map(m => {
    return App.Intl(
      `dates.month.${m.toLowerCase()}`.concat(monthShortNames ? '.short' : ''),
    );
  });
};

const getMonthsShort = () => getMonths(true);

const isBetween = (date, start, end) =>
  dateFns.isAfter(date, start) && dateFns.isBefore(date, end);

const isToday = date =>
  dateFns.isWithinInterval(date, {
    start: dateFns.startOfDay(new Date()),
    end: dateFns.endOfDay(new Date()),
  });

const isTomorrow = date =>
  dateFns.isWithinInterval(date, {
    start: dateFns.startOfDay(dateFns.addDays(new Date(), 1)),
    end: dateFns.endOfDay(dateFns.addDays(new Date(), 1)),
  });

const isFuture = date => dateFns.isAfter(date, new Date());

const isNextWeek = date => {
  const startOfNextWeek = dateFns.startOfDay(dateFns.addDays(new Date(), 1));
  const endOfNextWeek = dateFns.endOfDay(dateFns.addDays(startOfNextWeek, 5));
  return dateFns.isWithinInterval(date, {
    start: startOfNextWeek,
    end: endOfNextWeek,
  });
};

const isLastWeek = date => {
  const endOfLastWeek = dateFns.endOfDay(dateFns.subDays(new Date(), 1));
  const startOfLastWeek = dateFns.startOfDay(dateFns.subDays(endOfLastWeek, 5));
  return dateFns.isWithinInterval(date, {
    start: startOfLastWeek,
    end: endOfLastWeek,
  });
};

const calendar = (date, options) => {
  const settings = {
    sameDay: '[Today]',
    nextDay: '[Tomorrow]',
    nextWeek: 'dddd',
    lastDay: '[Yesterday]',
    lastWeek: '[Last] dddd',
    sameElse: App.Features('defaults.dateFormat'),
    ...options,
  };

  const { sameDay, nextDay, nextWeek, lastDay, lastWeek, sameElse } = settings;

  if (dateFns.isSameDay(new Date(), date) && sameDay) {
    return sameDay === '[Today]'
      ? App.Intl('date.today')
      : format(date, sameDay);
  }

  if (dateFns.isSameDay(date, dateFns.addDays(new Date(), 1)) && nextDay) {
    return nextDay === '[Tomorrow]'
      ? App.Intl('date.tomorrow')
      : format(date, nextDay);
  }

  if (isNextWeek(date) && nextWeek) {
    return format(date, nextWeek);
  }

  if (dateFns.isSameDay(date, dateFns.subDays(new Date(), 1)) && lastDay) {
    return lastDay === '[Yesterday]'
      ? App.Intl('date.yesterday')
      : format(date, lastDay);
  }

  if (isLastWeek(date) && lastWeek) {
    return lastWeek === '[Last] dddd'
      ? `${App.Intl('date.last')} ${format(date, 'dddd')}`
      : format(date, lastWeek);
  }

  return format(date, sameElse);
};

const badFormat = (dateStr, seperator = '/') => {
  // used to parse DD/MM/YYYY into a date as it is not a format supported by new Date();
  const [day, month, year] = dateStr.split(seperator);
  return new Date(year, month - 1, day);
};

export default {
  ...dateFns,
  getLocale,
  isBetween,
  isNextWeek,
  isLastWeek,
  isToday,
  isTomorrow,
  getMonths,
  getMonthsShort,
  format,
  formatDistance,
  formatDistanceStrict,
  toLocaleDateString,
  calendar,
  badFormat,
  isFuture,
};
