import color from 'color';

const setColors = ({ skinColors, skinComponentColors }) => {
  const background = skinColors.background || '#000E1A';
  const isLight = color(background).isLight();

  // we have to remove this ASAP but currently I cant remove
  // it until we make the theme better....
  const greyScale = {
    'grey-10': '#1a1a1a',
    'grey-15': '#222',
    'grey-20': '#2b2c2d',
    'grey-30': '#333333',
    'grey-35': '#3a3a3a',
    'grey-40': '#3d3e3f',
    'grey-50': '#434343',
    'grey-55': '#404040',
    'grey-60': '#515253',
    'grey-65': '#666',
    'grey-70': '#969696',
    'grey-75': '#777',
    'grey-80': '#bebebe',
    'grey-85': '#d6d6d6',
    'grey-87': '#e6e6e6',
    'grey-90': '#eee',
    'grey-95': '#ccc',
    'off-white': '#ededed',
  };

  // go crazy in here in the skinColors just these colors will
  // only be available to assign to the bellow keys do not use
  // in the project
  const c = {
    // TEXT COLORS
    mainText: isLight ? '#1a1a1a' : '#ffffff',
    secondaryText: '#7f939c',
    altText: isLight ? '#eee' : '#1a1a1a',
    extraText: '#969696',
    headingPrimary: '#7f939c',

    // BACKGROUND COLORS
    mainBg: isLight ? '#eee' : '#1a1a1a',
    altBg: isLight ? '#000' : '#fff',
    secondaryBg: '#7f939c',

    // MISC COLORS
    success: '#33e642',
    danger: '#ff1b43',
    error: '#ff0000',
    warning: '#ffc41b',
    score: '#ffc41b',
    live: '#33e642',
    ...greyScale,
  };

  // brand colors
  const bc = {
    bc1: '#0ab2d6', // highlights and actives
    bc2: '#000E1A', // backgrounds
    bc3: '#0d1f2b', // widgets and headers
    bc4: '#2a3842', // hover states
    bc5: 'unset',
  };

  // foreground colors
  const fc = {
    fc1: '#000000',
    fc2: '#000000',
    fc3: '#000000',
  };

  // opacity colors
  const fade = isLight ? '#000' : '#fff';
  const opacity = {
    op1: color(fade)
      .alpha(0.05)
      .hsl()
      .string(),
    op2: color(fade)
      .alpha(0.1)
      .hsl()
      .string(),
    op3: color(fade)
      .alpha(0.2)
      .hsl()
      .string(),
    op4: color(fade)
      .alpha(0.4)
      .hsl()
      .string(),
    op5: color(fade)
      .alpha(0.5)
      .hsl()
      .string(),
    op6: color(fade)
      .alpha(0.6)
      .hsl()
      .string(),
    op7: color(fade)
      .alpha(0.7)
      .hsl()
      .string(),
    op8: color(fade)
      .alpha(0.8)
      .hsl()
      .string(),
  };

  const borders = {
    borderMain: color(c.secondaryText)
      .alpha(0.5)
      .string(),
    borderHeavy: bc.bc2,
    borderActive: bc.bc1,
    borderAlt: color('white')
      .alpha(0.1)
      .string(),
  };

  const colors = {
    ...c,
    ...bc,
    ...fc,
    ...opacity,
    ...borders,
    background,
    ...skinColors,
  };

  const selectionBackBg = '#BFDEF9';
  const selectionBackSelectedBg = '#0D66E5';
  const selectionLayBg = '#FEC5E1';
  const selectionLaySelectedBg = '#FB0076';

  const backLay2DarkenFactor = 0.05;

  const selectionBackBg2 = color(selectionBackBg)
    .darken(backLay2DarkenFactor)
    .string();
  const selectionBackSelectedBg2 = color(selectionBackSelectedBg)
    .darken(backLay2DarkenFactor)
    .string();
  const selectionLayBg2 = color(selectionLayBg)
    .darken(backLay2DarkenFactor)
    .string();
  const selectionLaySelectedBg2 = color(selectionLaySelectedBg)
    .darken(backLay2DarkenFactor)
    .string();

  // colors available in this object can be used anywhere in a theme file.
  // If it is not in this file do not use it in theme files.
  const componentColors = {
    ...bc,
    /* ************************************
      Main
    ************************************ */
    htmlText: colors.mainText,
    extraText: colors.extraText,
    background: colors.background,
    appBorder: colors.borderAlt,
    accountPageBg: colors.background,
    boxShadow: '#888',
    gradientBg: `linear-gradient(to right, ${colors.bc1}, ${color(colors.bc1)
      .rotate(25)
      .hex()})`,
    gradientBgAlt: `linear-gradient(to right, ${colors.bc1}, ${color(colors.bc1)
      .darken(0.3)
      .hex()})`,

    /* ************************************
      Form colors
    ************************************ */
    formSuccess: colors.success,
    formSuccessColor: '#0e7533',
    formErrorColor: '#77091b',
    formDanger: colors.danger,
    formError: colors.error,
    formWarning: colors.warning,
    formSelected: colors.bc1,
    formBg: '#fff',
    formText: colors.mainText,
    formBorder: 'rgba(127, 147, 156, 0.5)',
    formHover: color('#000')
      .alpha(0.1)
      .hsl()
      .string(),
    formBorderAlpha: 'rgba(0,0,0,0)',
    genderInputActiveText: 'white',
    formDisabledBg: '#eee',
    formSelectOptionText: '#000',

    /* ************************************
      Link colors
     ************************************ */
    linkText: colors.mainText,
    linkActiveText: colors.bc1,

    /* ************************************
      Page Wrapper
     ************************************ */
    pageWrapperText: '#fff',
    pageWrapperInnerBackground: '#c3defd',

    /* ************************************
     Breadcrumb
    ************************************ */
    breadcrumbPaddedBg: 'unset',
    breadcrumbBg: colors.op1,
    breadcrumbItemLink: colors.mainText,
    breadcrumbDropdownBg: colors.background,
    breadcrumbDropdownAfterBg: colors.op1,
    breadcrumbDropdownText: colors.mainTitle,
    breadcrumbTitleActiveText: colors.op4,
    breadcrumbItemInfoText: colors.op4,
    breadcrumbDropdownItemHoverBg: colors.bc1,
    breadcrumbDropdownDropShadow: 'rgba(0, 0, 0, 0.80)',
    breadcrumbDropdownItemHoverText: '#fff',
    breadcrumbDropdownItemNoItemsText: '#7f939c',
    breadcrumbDropdownItemBorderBottom: 'rgba(0, 0, 0, 0.15)',

    /* ************************************
      Product nav
     ************************************ */
    productNavText: colors.mainText,
    productNavItem: colors.mainText,
    productNavItemActive: colors.bc1,
    productNavItemActiveLive: colors.live,
    productNavItemNewText: colors.altText,
    productNavItemNewBg: colors.error,
    productNavItemHover: colors.bc1,
    /* tabbed product nav */
    productNavTabbedBg: colors.background,
    productNavTabbedItemShadow: 'rgba(0,0,0,0.4)',
    productNavTabbedItemActiveBg: '#000',
    productNavTabbedItemHoverBg: colors.bc1,
    productNavTabbedItemHoverText: '#000',
    productNavTabbedItemActiveText: colors.bc1,
    /* small screen - mobile burger menu grid */
    productNavSmallScreenItemContainerBorder: '#fff',
    productNavSmallScreenItemText: colors.mainText,

    /* ************************************
      Gradient colors
    ************************************ */
    bgFadeFrom: 'rgba(0, 0, 0, 0.2)',
    bgFadeTo: colors.bc2,

    /* ************************************
      Buttons
     ************************************ */
    buttonText: '#fff',
    // if text is set to anything but true it will overide buttonText from above for that particular button
    'buttonText-primary': false,
    'buttonText-secondary': false,
    'buttonText-success': false,
    'buttonText-danger': false,
    'buttonText-error': false,
    'buttonText-warning': false,

    buttonLoadingBorder: '#fff',
    buttonDisabledBg: '#515253',
    buttonPrimaryInvertedBg: colors.bc1,
    buttonPrimaryInvertedBorder: colors.bc1,
    buttonPrimaryInvertedText: colors.bc1,

    // defines the background of each button
    'buttonBg-primary': colors.bc1,
    'buttonBg-secondary': colors.bc2,
    'buttonBg-success': colors.success,
    'buttonBg-danger': colors.danger,
    'buttonBg-error': colors.error,
    'buttonBg-warning': colors.warning,
    buttonGhostBorder: 'rgba(255,255,255,0.2)',
    buttonGhostHoverBg: '#fff',
    buttonGhostBg: '#fff',
    buttonGhostText: colors.bc1,
    buttonGhostHoverText: colors.bc1,
    buttonBaseBg: colors.bc1,
    suspendedBackground: '#ce3a22',

    /* ************************************
      ActionConfim
     ************************************ */
    actionConfimBg: colors.bc3,
    actionConfimText: '#000',
    actionConfimInnerBg: '#fff',
    actionConfimSvgStroke: colors.bc1,

    /* ************************************
      Promotions
      used in all types of promotions be carseful.
     ************************************ */
    promotionText: '#fff',
    promotionTitleText: '#fff',
    promotionSubTitleText: '#ebebeb',
    promotionSecondarySubTitleText: colors.bc1,
    promotionVsText: '#7b8c96',

    promotionTextVertical: colors.mainText,
    promotionTitleTextVertical: colors.bc1,
    promotionSubTitleTextVertical: colors.mainText,
    promotionSecondarySubTitleTextVertical: colors.mainText,
    promotionButtonTextVertical: colors.bc1,
    promotionalMarketsText: '#fff',
    promotionWidgetContainerBg:
      'linear-gradient(119deg, #003c77 12%, #001c42 113%)',

    /* ************************************
      InputWrapper
     ************************************ */
    inputWrapperBg: '#fff',
    inputWrapperText: colors.secondaryText,
    inputWrapperBorder: colors.borderMain,
    inputWrapperErrorBorder: colors.error,
    inputWrapperPlaceholderText: colors.extraText,
    inputWrapperSlideErrorBg: color(colors.error)
      .alpha(0.05)
      .string(),
    inputWrapperFocussedLabelText: color(colors.secondaryText)
      .alpha(0.4)
      .string(),
    inputWrapperLabelText: colors.htmlText,
    inputWrapperPasswordReveal: colors.htmlText,

    /* ************************************
      BurgerMenuInput
    ************************************ */
    burgerMenuInputWrapperInsideText: '#333',
    burgerMenuInputBg: colors.op1,

    /* ************************************
      Burger Toggle
    ************************************ */
    burgerToggleCountCircleFill: colors.danger,
    burgerToggleCountText: 'white',

    /* ************************************
      Modal
     ************************************ */
    modalBlur: color('#000')
      .alpha(0.4)
      .string(),
    modalText: '#000',
    modalInnerBg: '#fff',
    modalInnerText: '#000',
    modalInnerBorder: 'transparent',
    modalHeadBg: '#fff',
    modalClose: '#000',

    /* ************************************
      Header
    ************************************ */
    headerBg: colors.bc2,
    headerExternalBg: 'rgba(255, 255, 255, 0.05)',
    headerExternalBorder: undefined,
    headerText: colors.mainText,
    secondaryHeaderText: colors.secondaryText,
    headerNavigationBg: colors.bc1,
    headerBonusText: colors.warning,
    headerClockText: colors.mainText,
    headerBorder: colors.borderAlt,
    headerBalance: colors.success,
    headerFilterText: colors.secondaryText,
    headerDropdownText: 'inherit',
    headerLangDropdownText: colors.mainText,

    headerV3LoginText: colors.bc1,
    headerV3RegisterBorder: '#fff',
    headerSloganText: colors.bc1,

    /* ************************************
      HeaderMinimal
    ************************************ */
    headerMinimalBg: colors.bc2,
    headerMinimalText: colors.mainText,
    // search bar should use header bg
    headerMinimalSearchBg: color(colors.bc2)
      .lighten(0.2)
      .string(),
    headerMinimalSearchText: colors.mainText,
    // border should match text
    headerMiniamlBorder: colors.mainText,
    headerMinimalBalance: colors.success,

    /* ************************************
      Header Menu (Minimal)
    ************************************ */
    headerMenuBg: colors.bc3,
    headerMenuPlayIcon: colors['grey-60'],
    headerMenuStarIcon: colors['grey-60'],
    headerMenuSportsIcon: colors['grey-60'],

    /* ************************************
      Bonus Input
    ************************************ */
    bonusInputDetailsBg: '#ebebeb',

    /* ************************************
      User Dropdown
     ************************************ */
    userDropdownText: 'inherit',
    userDropdownTextActive: colors.bc1,
    userDropdownIcon: 'rgba(255, 255, 255, 0.4)',
    userDropdownIconActive: colors.bc1,
    userDropdownBalanceText: colors.bc1,
    userDropdownBalanceIcon: colors.bc1,
    userDropdownBalanceWallet: colors.bc1,
    userDropdownRegisterIcon: colors.bc1,
    userDropdownBonusText: colors.bc1,

    /* ************************************
      Dropdown
    ************************************ */
    genericDropdownBg: '#fff',
    genericDropdownText: colors.mainText,
    genericDropdownHeader: colors.headingPrimary,
    genericDropdownLink: colors.altText,
    genericDropdownLinkActive: colors.bc1,
    genericDropdownItemActive: colors.bc1,
    genericDropdownExpandedLinkBg: color(colors.bc1)
      .alpha(0.1)
      .string(),
    genericDropdownExpandedLinkText: colors.bc1,
    genericDropdownIconText: colors.bc1,

    /* ************************************
      Search
     ************************************ */
    searchResultText: 'unset',
    searchBg: colors.background,
    searchText: colors.mainText,
    searchOpenBg: 'unset',
    searchOpenText: colors.altText,
    searchBorder: 'unset',

    /* ************************************
      Dropdown
     ************************************ */
    dropdownBg: colors.altBg,
    dropdownText: colors.altText,
    dropdownArrow: colors.mainText,

    /* ************************************
      Dropdown V2
     ************************************ */
    dropdownV2Active: colors.bc2,
    dropdownV2ActiveBorder: colors.bc2,

    /* ************************************
      simple account menu
    ************************************ */
    simpleAccountMenuBg: 'white',
    simpleAccountMenuBorder: colors['grey-95'],
    simpleAccountMenuLogoBg: '#000',
    simpleAccountMenuHeaderBorder: colors['grey-95'],
    simpleAccountMenuCellHoverBg: colors.bc2,
    simpleAccountMenuCellActiveBorder: colors.bc1,
    simpleAccountMenuCellActiveText: colors.bc1,

    /* ************************************
      sports menu widget
    ************************************ */
    sportsMenuWidgetText: colors.mainText,
    sportsMenuWidgetBg: colors.bc2,
    sportsMenuWidgetCountText: colors.bc1,

    /* ************************************
      favourite Button
    ************************************ */
    favouriteButtonStarFill: 'rgba(255, 255, 255, 0)',
    favouriteButtonStarHoverFill: colors.op3,
    favouriteButtonStarActiveFill: colors.score,
    favouriteButtonStarStroke: null,
    favouriteButtonOutline: 'none',

    /* ************************************
      favourite Button Casino
    ************************************ */
    favouriteButtonStarCasinoFill: 'rgba(255, 255, 255, 0)',
    favouriteButtonStarCasinoHoverFill: colors.bc1,
    favouriteButtonStarCasinoActiveFill: colors.bc1,
    favouriteButtonStarCasinoStroke: colors.bc1,

    /* ************************************
      Burger - burgerMenu
      New burger styles the others are for the old
    ************************************ */
    get burgerMenuHeadingContainerBg() {
      return this.burgerBg;
    },
    burgerItemCountBg: 'unset',
    burgerItemBg: 'unset',
    burgerMenuHeadingContainerMobileBg: colors.bc1,
    burgerMenuHeadingBg: colors.op1,
    burgerMenuProductBg: colors.op1,
    burgerMenuHeadingHoverBg: colors.op2,
    burgerMenuHeadingShadow: color(colors.background)
      .alpha(0.5)
      .string(),
    burgerMenuHeadingText: 'inherit',
    burgerMenuHeadingMobileText: 'unset',
    burgerMenuHeadingBorder: colors.borderAlt,
    burgerMenuItemText: colors.mainText,
    burgerMenuItemHoverText: colors.mainText,
    burgerMenuItemActiveText: colors.bc1,
    burgerMenuItemActiveBg: 'unset',
    burgerMenuItemTrophy: colors.score,
    burgerMenuBgFade: color(colors.background)
      .alpha(0.5)
      .hsl()
      .string(),
    burgerMenuItemBg: colors.bc1,

    /* ************************************
    BurgerMenuV3
    ************************************ */
    burgerMenuBGV3: colors.bc2,
    burgerMenuProductsBGV3: color(colors.bc2)
      .lighten(0.2)
      .string(),
    // Product button
    burgerMenuProductBGV3: color(colors.bc2).isLight() ? 'black' : 'white',
    burgerMenuProductActiveBGV3: colors.bc1,
    burgerMenuProductsIconFillBGV3: colors.bc1,
    burgerMenuProductBrandV3: colors.bc1,

    /* ************************************
      Favourites part of new burgerMenu
     ************************************ */
    favouritesShadowGradientTop: color(colors.background)
      .alpha(0.3)
      .string(),
    favouritesShadowGradientBottom: colors.background,

    /* ************************************
      Burger
     ************************************ */
    leftNavBoxShadow: isLight ? '#000' : '#fff',
    burgerBg: colors.background,
    burgerFullScreenBg: colors.bc3,
    burgerTitles: colors.secondaryText,
    burgertext: color(colors.mainText)
      .fade(0.2)
      .string(),
    burgerTitlesHover: '#fff',
    burgerActiveProduct: colors.bc1,
    burgerAccountModalText: '#000',
    burgerClose: 'inherit',
    burgerHeaderBg: 'inherit',

    /* ************************************
      Burger sub item
     ************************************ */
    burgerSubItemText: colors.secondaryText,
    burgerSubItemActiveText: colors.bc1,
    burgerSubItemHoverText: colors.secondaryText,
    burgerSubItemBorder: colors.borderMain,
    burgerSubItemResultsText: colors.bc1,

    /* ************************************
      InplayItem
     ************************************ */
    inplayItemBg: 'transparent',
    inplayItemText: colors.mainText,
    inplayItemScoreText: '#000',
    inplayItemBorder: colors.borderAlt,
    inplayItemStatusText: colors.extraText,
    inplayStreamBg: color(colors.background)
      .darken(0.2)
      .string(),
    inplayTabText: colors.mainText,

    /* ************************************
      Register
    ************************************ */
    inputWrapperInputBg: undefined,
    inputWrapperLabelColor: undefined,
    registerBg: '#fff',
    registerText: '#000',
    registerHeaderText: colors.mainText,
    registerTermsBg: colors.bc2,
    registerTermsLink: colors.bc1,
    registerTermsText: colors.fc2,
    registerStepTitle: colors.secondaryText,
    registerBackText: colors.secondaryText,
    registerPaginationText: colors.secondaryText,
    registerEdgeButtonColor: '#fff',
    registerSignUpText: colors.bc1,
    registerTermsLabelTextColor: 'inherit',
    mandatoryFieldAsterisk: 'red',
    newCustomerText: colors.mainText,
    fieldDisclaimerTextColor: colors.bc1,
    fieldDisclaimerIconColor: colors.danger,
    termsCheckboxAndTheColor: undefined,

    /* ************************************
      PriorityNav (Widget Header Tabs)
     ************************************ */
    priorityNavItemContainerColor: undefined,
    priorityNavItemSportMenuActiveBg: undefined,
    priorityNavItemSportMenuBg: 'transparent',
    priorityNavBackground: colors.background,
    priorityNavText: colors.headingPrimary,
    priorityNavDivider: '#fff',
    prioritySportsMenuBg: colors.bc3,
    priorityNavInverseText: undefined,
    priorityNavInverseActiveText: '#000',
    priorityNavInverseActiveTextV2: '#fff',
    priorityNavItemActiveText: colors.bc1,
    priorityNavItemDisabledText: colors['grey-60'],
    priorityNavLoadingBarBg: colors.bc1,
    priorityNavItemHeaderText: colors.mainText,
    priorityNavItemHeaderHoverText: colors.bc1,
    priorityNavItemInHeaderActiveText: colors.bc1,
    smallPriorityNavItemInHeaderActiveText: colors.bc1,
    priorityNavItemActiveBg: colors.bc3,
    priorityNavItemSportMenuActiveText: colors.bc1,
    priorityNavItemSportMenuText: colors.bc2,
    priorityNavItemActiveText: color(colors.bc1)
      .lighten(0.2)
      .hex(),
    priorityNavItemBorderBottom: colors['grey-70'],
    priorityNavItemActiveBorderBottom: colors.bc1,
    priorityNavToggleBg: colors.background,
    priorityNavToggleText: colors.mainText,
    priorityNavTabBg: 'transparent',
    priorityNavTabBorder: 'transparent',
    priorityNavShadow: colors.background,
    priorityNavInverseShadow: 'rgba(0,0,0,0.1)',
    priorityNavArrow: '#fff',
    priorityNavPrimaryShadow: colors.bc1,
    priorityNavInHeaderBg: 'transparent',
    priorityNavInHeaderMobileBg: undefined,

    /* ************************************
      Tabs  used in page wrapper. (Not Prio Nav)
     ************************************ */
    tabsBorder: colors.borderMain,
    tabsActiveTabBorder: colors.borderActive,
    tabsActiveTabText: colors.bc1,
    tabsHightlightBackground: colors.bc2,
    tabsText: colors['grey-70'],

    /* ************************************
      Widget
    ************************************ */
    widgetContainerBg: 'transparent',
    widgetHeaderBg: colors.background,
    widgetHeaderText: colors.headingPrimary,
    widgetBg: 'transparent',
    widgetChildBg: colors.bc3,
    widgetText: colors.mainText,
    widgetRowBg: 'transparent',
    widgetRowText: colors.mainText,
    widgetChildBgAlt: colors.altBg,
    // insideBetslip
    widgetIbText: colors.mainText,
    widgetIbHeaderBg: colors.op2,

    /* ************************************
      WidgetFooter (Show More Link)
    ************************************ */

    widgetShowMoreTextColor: colors.headingPrimary,
    widgetShowMoreTextColorHover: colors.bc1,
    widgetFooterBackground: colors.bc3,
    widgetFooterBackgroundHover: color(colors.bc3)
      .lighten(0.1)
      .string(),

    /* ************************************
      Loader View
    ************************************ */
    loadingOverlay: color(colors.background)
      .alpha(0.6)
      .string(),
    loadingOverlayCircle: colors.fc3,
    loadingSpinner: colors.bc1,

    /* ************************************
      VerifyView
    ************************************ */
    verifyViewRedStatusBg: colors.bc1,
    verifyViewRedStatusText: colors.mainText,
    verifyViewRedStatusBorder: colors.bc1,
    verifyViewRedStatusHoverBg: 'white',
    verifyViewRedStatusHoverText: colors.bc1,
    verifyViewEditableText: colors.bc1,
    verifyViewUpdateText: colors.bc1,
    verifyViewFinishedText: colors.mainText,
    verifyViewCheckText: colors.altText,
    verifyViewCheckBg: colors.success,
    verifyViewLozengeBg: colors.altBg,
    verifyViewLozengeText: colors.bc1,
    verifyViewLozengeBorder: colors.borderAlt,
    verifyViewLabel: colors.htmlText,
    verifyViewRowBorder: colors['grey-85'],
    verifyNowLinkBg: 'unset',
    verifyNowLinkColor: colors.mainText,

    /* ************************************
      Betslip
    ************************************ */
    singleBetGroupRemoveBetColor: colors.secondaryText,
    selectElementBg: 'transparent',
    betslipWrapperBg: 'transparent',
    betslipBg: colors.bc2,
    betslipBgMobile: color(colors.bc2)
      .darken(0.5)
      .string(),
    betslipBorder: colors.borderAlt,
    betslipText: colors.secondaryText,
    betslipTabs: colors.mainText,
    betslipTabActive: colors.borderActive,
    betslipTabActiveBorder: colors.borderActive,
    betslipTabsBorderBottom: colors.borderAlt,
    betslipTextSecondary: colors.altText,
    betslipMaxText: colors.bc1,
    betslipClearButtonText: colors.secondaryText,
    betslipSectionHeader: colors.bc1,
    betslipClearButton: 'rgba(127, 147, 156, 0.2)',
    betslipDottedBorder: color(colors.bc2)
      .alpha(0.2)
      .string(),
    betslipBottomTabBg: colors.bc2,
    betslipSuccessBg: colors.success,
    betslipPendingBg: colors.secondaryBg,
    betslipOverAskOfferBg: colors.score,
    betslipDangerBg: colors.danger,
    betslipDangerText: colors.mainText,
    betslipWarningBg: colors.warning,
    betslipWarningText: colors.mainText,
    betslipCountryRuleRejectText: colors.danger,
    betslipQuinellaMultiplierText: colors.bc1,
    betslipTabIconText: colors.mainText,
    betSlipBgFade: color(colors.background)
      .alpha(0.5)
      .hsl()
      .string(),
    betSlipHeaderContainerShadow: 'rgba(0,0,0,0.4)',
    betslipTabsBg: colors.background,
    betslipWarning: colors.warning,
    betslipCloseContainerBg: colors.bc1,
    betslipCancelBetHoverText: 'inherit',
    betslipHeaderText: 'inherit',
    betslipTabsBackground: 'transparent',
    betTypeTab: undefined,
    betTypeTabs: colors.mainText,
    betTypeTabsBg: 'transparent',
    betTypeTabsActiveBg: '#065092',
    betTypeTabActive: colors.borderActive,
    betTypeTabActiveBorder: colors.borderActive,
    betTypeTabsBorderBottom: colors.borderAlt,
    betslipBackLayText: '#000',
    betslipBackLayTextSecondary: 'rgba(0,0,0,0.4)',
    betslipWrapperText: '#7c7c7c',
    betslipStakeButtonBorder: '#969696',
    betslipBrandColor: colors.bc1,
    betSlipClosedBetsBackground: 'transparent',
    betslipContainerNotEmpty: 'transparent',

    /* ************************************
      Betslip notification
    ************************************ */
    betslipNotificationBg: colors.bc1,
    betslipNotificationText: colors.mainText,
    betslipNotificationShadow: 'rgba(70, 70, 70, 0.5)',

    /* ************************************
      BetSlipMobileToggle
     ************************************ */
    betSlipMobileToggleBg: colors.bc1,
    betSlipMobileToggleText: '#fff',

    /* ************************************
      Betslip freebet
    ************************************ */
    betslipFreebetText: colors.altText,

    /* ************************************
      Betslip freeSpin
   ************************************ */
    betslipFreespinText: colors.altText,

    /* ************************************
      Betslip Scan
    ************************************ */
    betslipScanModalBg: colors.bc2,
    betslipScanModalText: colors.mainText,
    betslipScanPartBg: colors.altBg,
    betslipScanPartText: colors.secondaryText,
    betslipScanPartHeaderText: colors.bc1,
    betslipScanPartStatusBg: colors.bc3,
    betslipScanPartStatusText: colors.mainText,
    betslipScanPartStatusWinText: colors.success,
    betslipScanPartStatusLoseText: colors.danger,

    /* ************************************
     Betslip item
    ************************************ */
    betslipItemBg: colors.altBg,
    betslipItemText: colors.secondaryText,
    betslipItemHeaderHeadingText: colors.bc1,
    betslipItemHeaderClearText: colors.secondaryText,
    betslipItemHeaderScoreBg: colors.score,
    betslipItemHeaderScoreText: color(colors.bc2)
      .alpha(0.4)
      .string(),
    betslipItemHeaderLiveBg: colors.live,
    betslipItemHeaderLiveText: color(colors.bc2)
      .alpha(0.4)
      .string(),

    /* ************************************
     Betslip group single bets combi bets
    ************************************ */
    betslipGroupTitleText: colors.headerText,
    betslipCombiBetsWarningText: '#fea63a',
    betslipCombiBetsWarningBorder: '#fea63a',

    /* ************************************
     Betslip summary
    ************************************ */
    betslipSummaryBg: colors.bc2,
    betslipSummaryText: colors.mainText,
    betslipSummaryEmptyNoticeText: colors.secondaryText,
    betslipSummaryEmptyNoticeHeaderText: colors.secondaryText,
    betslipSummarySelectText: colors.mainText,
    betslipSummarySelectBg: 'transparent',
    betslipSummaryLabels: colors.op5,
    betslipSelectIcon: colors.bc1,
    betslipSummaryTotalReturnsText: 'inherit',
    singleGroupedBetBodyColor: undefined,

    /* ************************************
     Show Bet Details (inside the betslip)
    ************************************ */
    showBetDetailsBetsText: colors.mainText,
    selectedRadioButtonBackground: colors.bc1,
    showBetDetailsRadioButtonColor: colors.bc2,
    showBetDetailsSelectedLabel: colors.secondaryText,

    /* ************************************
     Betslip Confirmation
    ************************************ */
    betslipConfirmationText: colors.mainText,
    betslipConfirmationBetBg: colors.altBg,
    betslipConfirmationBetText: colors.altText,
    betslipConfrimationOddsText: colors.bc1,
    betslipConfirmationSummaryBg: colors.op2,

    /* ************************************
     BetslipIconCount
    ************************************ */
    betSlipIconCountCircleFill: colors.bc2,

    /* ************************************
      Betslip Input Fields
    ************************************ */
    betslipInputBackgroundColor: 'white',
    betslipInputBorderColor: '#ccc',
    betslipInputTextColor: colors.background,

    /* ************************************
     Betslip mobile toggle
    ************************************ */
    betslipMobileToggleLozengeBg: '#fff',
    betslipMobileToggleLozengeText: colors.bc1,

    /* ************************************
      Quick Betslip
    ************************************ */
    quickBetslipBg: colors.background,
    quickBetslipErrorBg: colors.bc2,
    quickBetslipText: colors.mainText,
    quickBetslipReturnTextColor: 'white',
    quickBetslipBetInfoBgColor: 'white',
    quickBetslipStakeBtnColor: colors.mainTedt,
    quickBetslipStakeBtnBg: undefined,
    quickBetslipMktName: colors['grey-40'],
    quickBetslipDeleteButtonBg: colors.danger,
    quickBetslipDeleteButtonText: '#fff',

    quickBetslipBetInfoBgColorMobile: 'white',
    quickBetslipBetInfoTextMobile: 'black',
    quickBetslipBetInfoSecondaryTextMobile: 'rgba(0,0,0,0.5)',
    keyboardKeyTextMobile: '#000',
    keyboardKeyBackgroundMobile: '#fff',
    keyboardKeyTextMobileActive: '#fff',
    keyboardKeyBackgroundMobileActive: colors.bc1,
    betslipInputBackgroundColorMobile: '#000',
    betslipInputTextColorMobile: '#fff',

    /* ************************************
      Build a bet
    ************************************ */
    participantBabSelectedColor: colors.bc1,
    betslipBabBackground: 'transparent',

    /* ************************************
      TeaserBets
    ************************************ */
    betslipAdjustsSelectorBorder: colors.bc1,
    betslipAdjustsSelectorTextColor: colors.bc1,
    betslipSelectedAdjustBackgroundColor: colors.bc1,
    betslipSelectedAdjustTextColor: '#fff',
    betslipTeaserBetWarningMessageBackgroundColor: colors.bc1,
    betslipTeaserBetWarningMessageTextColor: '#fff',
    betslipTeaserBetAdjustedLineValueTextColor: colors.bc1,

    /* ************************************
     BuiltSingleBets
     ************************************ */
    builtSingleBetsLozengeBg: colors.bc1,
    builtSingleBetsLozengeBorder: colors.borderMain,
    builtSingleBetsLozengeText: colors.mainText,
    builtSingleBetsEventText: colors.secondaryText,
    builtSingleBetsSelectionText: colors.extraText,
    builtSingleBetsIcon: colors.extraText,

    /* ************************************
      BetsWrapper  used for built single bets
    ************************************ */
    betsWrapperBg: colors.altBg,
    betsWrapperHeaderBg: colors.bc3,
    betsWrapperIcon: colors.secondaryText,

    /* ************************************
      Mobile Keyboard on betslip
    ************************************ */
    keyboardBackground: 'white',
    keyboardKeyBackground: 'white',
    keyboardKeyText: 'black',
    keyboardKeyDone: 'inherit',

    /* ************************************
      All bets
    ************************************ */
    allBetsBorder: colors.borderMain,
    allBetsFilterBoxText: colors.mainText,
    allBetsFilterBoxBg: '#fff',
    allBetsActiveCal: colors.success,
    allBetsCheckBackground: colors.success,
    allBetsCheckIcon: '#fff',
    allBetsArrow: colors['grey-70'],
    allBetsContainerBg: colors.background,
    allBetsNoBetsText: colors['grey-70'],

    /* ************************************
     OpenBets
    ************************************ */
    openBetsSummaryText: colors.secondaryText,
    openBetsBackground: undefined,

    /* ************************************
     ClosedBet
    ************************************ */
    closedBetBorder: colors.borderAlt,
    closedBetText: colors['grey-55'],
    closedBetBg: colors['off-white'],
    closedBetSectionBorder: '#323232',
    closedBetHeaderBg: colors['off-white'],
    closedBetWinText: colors.success,
    closedBetCashedText: colors.success,
    closedBetLoseText: colors.error,
    closedBetOtherText: colors.warning,
    closedBetSummaryText: colors['grey-55'],

    /* ************************************
      Race Result
     ************************************ */
    raceResultHeaderBg: colors.bc2,
    raceResultHeaderText: colors.secondaryText,
    raceResultTableHeaderBg: color(colors.bc2)
      .alpha(0.005)
      .string(),
    raceResultRowBorder: colors.borderMain,
    raceResultRaceCardText: colors.secondaryText,
    raceResultNonRunnersBg: colors['grey-87'],

    /* ************************************
          CompetitionView
      ************************************ */
    competitionViewHeaderBg: colors.background,
    competitionViewTabText: colors.headingPrimary,
    competitionViewActiveTabBg: colors.bc3,
    competitionViewActiveTabText: colors.bc1,
    competitionViewActive: colors.bc1,

    /* ************************************
          MarketGroupSelector - market nav tabs
      ************************************ */
    marketGroupSelectorBackground: colors.bc3,
    marketGroupSelectorText: colors.headingPrimary,
    marketGroupSelectorActive: colors.bc1,
    marketGroupSelectorTextActive: colors.bc1,
    marketGroupSelectorBorder: 'transparent',
    marketTypeSelectiorBg: 'unset',
    marketTypeSelectorBorderBottom: colors.bc1,

    /* ************************************
      MarketView - event markets MarketView.jss
     ************************************ */
    marketsHeaderWrapperBg: undefined,
    marketHeaderTypeSelectionText: 'unset',
    marketViewIcon: colors.mainText,
    marketViewHeaderBg: colors.op2,
    marketViewHeaderHoverBg: colors.op3,
    marketViewHeaderText: colors.mainText,
    get marketViewTitleBg() {
      return this.marketViewHeaderBg;
    },
    get marketViewTitleText() {
      return this.widgetText;
    },
    marketViewPlayerNameBg: colors.marketViewTitleBg,
    marketViewBodyBg: colors.background,
    marketContainer: 'transparent',
    marketContainerBorder: colors.background,
    marketViewBorder: colors.background,
    marketsFaveAndNumberContainerText: isLight ? 'black' : 'white',
    marketsViewAsianBg: isLight ? 'white' : 'black',
    marketsViewAsianText: colors.mainText,
    marketsViewAsianEvenZebraBg: 'rgba(255,255,255,0.03)',
    marketsViewLineText: 'inherit',
    marketsBackLayGutter: colors.background,
    marketsBackLayBorder: 'rgba(0,0,0,0.1)',
    marketsBackLayBg: '#fff',
    marketsBackLayGutterSecondary: colors.background,
    marketsBackLayText: '#000',
    marketsBackLayTextSecondary: 'rgba(0,0,0,0.4)',
    streamPlayIcon: colors.live,

    /* ************************************
      Markets Stats  StatsButton.jss
    ************************************ */
    marketStatsActiveButton: colors.bc1,

    /* ************************************
      Markets Header  MarketsHeader.jss
    ************************************ */
    marketHeaderBg: colors.op2,
    marketHeaderDayTitleText: colors.mainTitle,
    marketHeaderHoverBg: colors.op3,
    marketHeaderShortType: 'rgba(255, 255, 255, 0.3)',
    marketHeaderBorder: color(colors.bc3)
      .darken(0.5)
      .string(),
    marketText: colors.fade,
    marketTextHeader: colors.fade,
    marketHeaderText: colors.fade,
    marketTypeText: colors.op4,
    marketTypeSeperator: color(colors.mainText)
      .alpha(0.08)
      .string(),
    marketTypePromoText: '#fff',
    marketHeaderEventCountText: 'rgba(0,0,0,0.4)',
    marketEventCountBg: color(colors.mainText)
      .fade(0.85)
      .string(),

    /* ************************************
      MarketRow
    ************************************ */
    marketRowBg: colors.op1,
    marketRowBgHover: colors.op2,
    marketRowText: colors.mainText,
    marketRowBorder: colors.bc2,
    marketRowAltBg: 'transparent',
    marketRowAltText: colors.altText,
    marketRowAltBorder: colors.bc2,
    marketRowVsText: colors.secondaryText,
    marketRowNeautralVenueText: colors.bc1,
    marketRowSelectionSeperator: color(colors.mainText)
      .alpha(0.08)
      .string(),
    marketRowSelectionSeperatorAlt: color(colors.altText)
      .alpha(0.08)
      .string(),
    marketRowCompetitionLink: colors.bc1,
    marketRowSeparator: color(colors.bc2)
      .alpha(0.3)
      .string(),

    /* ************************************
      MarketNumber
    ************************************ */
    marketNumberText: 'inherit',
    marketNumberExtraText: colors.mainText,
    marketNumberBackground: 'transparent',

    /* ************************************
      ErrorBox
     ************************************ */
    errorBoxText: colors.error,
    errorBoxBackground: colors.bc2,
    errorBoxBorder: 'transparent',
    errorBoxArrowBorder: colors.error,
    errorBoxExclamationText: '#fff',
    errorBoxExclamationBg: colors.error,

    /** ***********************************
      Selection
     ************************************ */
    selectionBg: colors.bc3,
    selectionBgHover: colors.op2,
    selectionText: colors.mainText,
    selectionTextHover: undefined,
    selectionBlockBg: colors.op1,
    selectionBlockTextHover: undefined, // most themes dont need to use this
    selectionBlockBgHover: colors.bc4,
    selectionBlockBorder: colors.bc1,
    selectionBlockText: colors.bc1,
    selectionBlockAltBg: colors.op1,
    selectionBlockBgAltHover: color('#000')
      .fade(0.95)
      .string(),
    selectionBlockAltText: colors.bc1,
    selectionSimpleBg: colors.bc3,
    selectionEmptyText: colors.op2,
    selectionSelectedText: colors.mainText,
    selectionSelectedBg: colors.bc1,
    selectionSelectedBorder: false,
    selectionParticipantText: colors.mainText,
    selectionParticipantSelectedText: colors.mainText,
    participantHighlightText: colors.mainText,
    selectionDisabledText: colors.op4,
    selectionDisabledTextBg: undefined,
    selectionDisabledBg: colors.op1,
    selectionDisabledStripes: 'unset',
    selectionDisabledAltBg: 'transparent',
    selectionDisabledAltText: '#ccc',
    selectionScoreText: colors.score,
    selectionPriceBlockBg: colors.op2,
    selectionPriceBlockText: colors.bc1,
    selectionPriceBlockAltText: colors.bc1,
    selectionBgInHighlights: 'rgba(0, 0, 0, 0.2)',
    selectionTextInHighlights: 'white',
    selectionBlockAsian: 'white',
    selectionEventPageBorder: undefined,
    selectionEventPageBg: undefined,
    selectionBackMarketBg: selectionBackBg,
    selectionBackMarketBgActive: selectionBackSelectedBg,
    selectionBackMarketBgHover: color(selectionBackBg)
      .darken(0.1)
      .string(),
    selectionLayMarketBg: selectionLayBg,
    selectionLayMarketBgActive: selectionLaySelectedBg,
    selectionLayMarketBgHover: color(selectionLayBg)
      .darken(0.1)
      .string(),

    selectionBackMarketBg2: selectionBackBg2,
    selectionBackMarketBgActive2: selectionBackSelectedBg2,
    selectionBackMarketBgHover2: color(selectionBackBg2)
      .darken(0.1)
      .string(),
    selectionLayMarketBg2: selectionLayBg2,
    selectionLayMarketBgActive2: selectionLaySelectedBg2,
    selectionLayMarketBgHover2: color(selectionLayBg2)
      .darken(0.1)
      .string(),
    selectionBackLaySelectedText: '#fff',
    selectionLayText: '#fff',
    suspendedSelectionText: '#989898',

    /* ************************************
      highlightsWidget Card
    ************************************ */
    highlightsWidgetCardText: '#fff',
    highlightsWidgetCardBg: '#000',
    highlightsWidgetCardHeaderBg: 'rgba(0, 0, 0, 0.5)',
    highlightsWidgetCardLive: colors.score,
    highlightsWidgetCardComingUp: colors.live,
    highlightsWidgetCardToday: colors.bc1,
    highlightsWidgetCardAltText: colors.altText,
    highlightsWidgetCardScoreTextVs: 'grey',
    cardsHeaderColor: '#fff',

    /* ************************************
      Priceblock
    ************************************ */
    priceBlockUp: colors.success,
    priceBlockDown: colors.danger,
    priceBlockSelected: colors.mainText,
    priceBlockPrice: colors.bc1,

    /* ************************************
      Checkbox
      ************************************ */
    checkboxBorder: 'rgba(127, 147, 156, 0.5)',
    checkboxShadow: 'rgba(0, 0, 0, 0.05)',
    checkboxCheckedBorder: '#91e633',

    /* ************************************
      Race Card
      ************************************ */
    raceCardBg: colors.bc2,
    raceCardSectionBg: colors.bc1,
    raceCardEventBg: '#fff',
    raceCardBoxShadow: colors['grey-60'],
    raceCardTimeText: colors.secondaryText,
    raceCardMoreText: colors.bc1,
    raceCardMoreBorder: colors.bc1,
    raceCardLessBorder: colors.bc1,
    raceCardPlayBorder: colors.bc1,
    raceCardShowAllBackground: colors.altBg,
    raceCardHeaderBackground: colors.bc2,
    raceCardsLink: colors.mainText,

    /* ************************************
      Race Card WL
     ************************************ */
    raceCardWLBg: 'transparent',
    raceCardHeaderBg: colors.op2,
    raceCardInfoBg: colors.op1,
    raceCardTitleText: colors.mainText,
    raceCardWLText: colors.op5,
    raceCardRowWLBg: color(colors.altBg)
      .alpha(0.05)
      .string(),
    raceCardHeaderWLText: colors.op5,
    raceCardHeaderWLBg: colors.bc3,
    raceCardWLSelectionInfoText: colors.mainText,
    raceCardWLSelectionJockeyText: colors.op8,
    raceCardDashText: colors.op4,
    raceCardsSectionColor: colors.mainText,
    raceCardsSectionBg: color(colors.bc1)
      .alpha(0.95)
      .string(),

    /* ************************************
      Race Selection
     ************************************ */
    raceSelectionBorder: colors.borderMain,
    raceSelectionInfoText: colors['grey-60'],
    raceSelectionPriceHistoryText: 'rgba(255,255,255,0.6)',
    raceSelectionExpandedText: colors['grey-30'],
    raceSelectionExpandChildBorder: colors['grey-90'],
    raceSelectionExpandChildBg: colors.background,
    raceSelectionExpandHeaderBorder: colors['grey-90'],
    raceSelectionStats: colors.bc1,
    raceSelectionFormText: colors['grey-65'],

    /* ************************************
      RacingEVentsView
    ************************************ */
    racingEventsViewHeaderBg: colors.op2,
    racingEventsViewHeaderText: colors.mainText,
    racingEventsViewCheckboxBorder: colors.background,
    racingEventsViewEmptyBg: colors.op1,
    racingEventsViewIcon: colors.op4,

    /* ************************************
      Race Checkbox WL
    ************************************ */
    raceCheckboxContainerBg: colors.op1,
    raceCheckboxContainerHoverBg: colors.op2,
    raceCheckboxBg: colors.bc1,
    raceCheckboxLabelBg: colors.op2,

    /* ************************************
      RacingSelector
    ************************************ */
    racingSelectorActiveBorder: colors.bc1,

    /* ************************************
      Daily List Page
     ************************************ */
    dailyListBackButtonBg: color(colors.bc3)
      .darken(0.2)
      .string(),
    dailyListBackbuttonText: colors.bc1,
    dailyListDateBarBg: color(colors.bc3)
      .darken(0.2)
      .string(),
    dailyListDateBarActiveBg: colors.bc1,
    dailyListBackbuttonTextActive: colors.mainText,

    /* ************************************
      Sport screen competitions
    ************************************ */
    sportScrnCompetitionsBg: colors.bc3,
    sportScrnCompetitionsLabelInfoText: color(colors.mainText)
      .alpha(0.6)
      .string(),
    sportScrnCompetitionsLabelValueText: colors.mainText,
    sportScrnFallbackBg: colors.bc3,
    sportScrnFallbackText: colors.mainText,

    /* ************************************
      Results page (old)
    ************************************ */
    resultsEventTitleBg: colors.bc3,
    resultsRowBg: colors.op2,
    resultsRowHeaderBg: colors.bc3,
    resultsMarketBg: colors.bc3,
    resultsMarketColor: colors.mainText,

    /* ************************************
      Results page
    ************************************ */
    resultsNoResults: colors.op1,
    resultsFilterBg: colors.op1,
    resultsCountryBg: colors.op1,
    resultsCountryHoverBg: colors.op2,
    resultsEventBg: colors.op1,
    resultsEventHoverBg: colors.op2,
    resultsEventViewBg: colors.op1,
    resultsEventBorder: colors.background,
    resultsEventNoMarketsBg: colors.op2,
    resultsEventMarketHeaderBg: colors.op2,
    resultsEventMarketSelectionBg: colors.op1,
    resultsWinText: colors.success,
    resultsLoseText: colors.danger,
    resultsBg: 'inherit',
    resultsTitleBackgroundLinearMain: colors.bc1,
    resultsTitleBackgroundLinearSecondary: colors.background,
    resultsTitleText: '#ffffff',
    resultsTitleScoreBackground: '#000000',
    resultsTitleScoreText: '#ffffff',

    /* ************************************
      Colapsable Container
    ************************************ */
    colapsableContHeaderText: colors.mainText,
    colapsableContHeaderBorder: colors.borderHeavy,
    colapsableContHeaderBg: colors.op1,
    colapsableContHeaderSecBg: colors.op2,
    colapsableContHeaderTerBg: colors.op3,

    /* ************************************
      Lozenge
    ************************************ */
    lozengePrimaryBg: colors.bc1,
    lozengePrimaryText: '#fff',
    lozengeSecondaryBg: colors.bc2,
    lozengeSecondaryText: '#fff',
    lozengeTertiaryBg: colors.bc3,
    lozengeTertiaryText: '#fff',
    lozengeBetCountBg: colors.bc1,
    lozengeOutlineBorder: colors.bc1,
    lozengeAlternativeBg: color('#7f939c')
      .alpha(0.4)
      .string(),

    /* ************************************
      Racing Widget
     ************************************ */
    racingWidgetMenuBg: color('#2b2929')
      .alpha(0.4)
      .string(),
    racingWidgetClearBg: '#fff',
    racingWidgetViewRacesBg: colors.bc1,
    racingWidgetViewRacesText: '#fff',
    racingWidgetTopText: colors.mainText,
    racingWidgetTopBg: '#fff',
    racingWidgetIcon: colors.bc1,
    racingWidgetNoRaceText: colors['grey-70'],
    racingWidgetLiveText: colors.live,

    /* ************************************
      Racing country
     ************************************ */
    raceCountryText: colors.mainText,
    raceCountryBg: '#fff',
    raceCountryBoxShadow: colors['grey-60'],
    raceCountryHeaderBorder: colors.borderMain,
    raceCountryHeaderText: colors.mainText,
    raceCountryHeaderBg: colors.bc2,
    raceCountryIcon: colors['grey-75'],
    raceCountryDateText: colors['grey-60'],

    /* ************************************
      RaceCards
     ************************************ */
    raceCardsBorder: colors.borderMain,

    /* ************************************
      Racing Checkbox
     ************************************ */
    racingCheckboxText: 'inherit',
    racingCheckboxBorder: colors.borderMain,

    /** ***********************************
     * Mobile Navigation
     ************************************ */
    mobileNavBg: '#fff',
    mobileNavText: colors['grey-80'],
    mobileNavBorder: colors['grey-90'],
    mobileNavItemSelectedText: colors.bc1,
    mobileNavItemSelectedBorder: colors.bc1,

    /* ************************************
      Live Clock
    ************************************ */
    liveClockText: colors.live,
    liveClockBg: color(colors.bc2)
      .alpha(0.4)
      .string(),

    /* ************************************
      Search field
    ************************************ */
    searchFieldText: isLight ? '#1a1a1a' : '#ffffff',
    searchFieldMobileText: 'inherit',

    searchFieldActiveText: 'inherit',
    searchFieldActiveBg: 'inherit',
    searchFieldIconColor: 'unset',

    /* ************************************
      Search results
    ************************************ */
    searchResultsEndBg: colors.op2,
    searchResultsEndText: colors.mainText,

    /* ************************************
      Spinner
    ************************************ */
    spinnerPrimary: colors.bc1,
    spinnerSecondary: colors.mainText,
    spinnerTertiary: '#ddd',

    /* ************************************
      InfoBox
    ************************************ */
    infoBoxBg: color(colors.bc2)
      .alpha(0.4)
      .string(),
    infoBoxLiveClockText: colors.live,
    infoBoxScoreText: colors.score,
    infoBoxScoreExtraText: colors.mainText,
    infoBoxUpcomingTimeText: colors.secondaryText,
    infoBoxBorder: 'transparent',
    infoBoxLiveClockBg: 'transparent',
    infoBoxLiveClockBorder: 'transparent',
    infoViewPitchersColor: colors.bc1,

    /* ************************************
      A-Z Sports
    ************************************ */
    aZBorder: colors.borderMain,
    aZText: colors.mainText,

    /* ************************************
      Footer
    ************************************ */
    footerCopyrightBg: colors.bc2,
    footerBg: colors.background,
    footerText: colors.mainText,
    footerLinkText: colors.mainText,
    footerTitleText: colors.mainText,
    footerLinkHover: undefined,
    footerBorder: colors.borderAlt,
    footerFadedText: color(colors.mainText)
      .alpha(0.6)
      .string(),
    footerClockText: color.mainText,

    hoverState(originalColor) {
      return color(originalColor)
        .lighten(0.5)
        .hex();
    },
    footerType4SquareBg: '#272727',
    footerType4BorderColor: '#565656',

    /* ************************************
      TurfDepositView
    ************************************ */
    spacerBackground: colors.bc2,
    spacerText: colors.htmlText,
    depositAmountButton: colors.borderMain,
    selectedAmountButton: colors.bc1,

    /* ************************************
      BankDetailsView
    ************************************ */
    bankDetailsViewHeaderBg: colors.background,
    bankDetailsViewBorder: colors.borderMain,

    /* ************************************
      OtherHorseBets
    ************************************ */
    previewText: colors.bc1,
    previewBackground: colors.bc2,
    otherHorseBetButton: colors['grey-70'],
    otherHorseBetButtonActive: colors.bc1,
    otherHorseBetRowBorder: colors['grey-80'],
    otherHorseBetCheckBox: colors['grey-80'],
    otherHorseBetCheckBoxDisabled: colors['grey-95'],
    racingCheckboxBackground: colors.success,
    racingCheckboxTick: 'white',

    /* ************************************
      BetBody
    ************************************ */
    betBodyRaceRef: colors['grey-70'],
    playButton: colors.bc1,

    /* ************************************
      Toast
    ************************************ */
    toastBackground: colors.background,
    toastSuccess: colors.success,
    toastText: colors['grey-75'],

    /* ************************************
      TextHeader
    ************************************ */
    leftSidedTextHeaderColor: 'white',

    /* ************************************
      Account
    ************************************ */
    pageWrapperModalContainerBg: undefined,
    selfExclussionViewColor: undefined,
    accountType: undefined,
    bonusViewErrorColor: undefined,
    userDropDownViewsLogoutBg: undefined,
    textHeaderH1TextColor: undefined,
    buttonLikeAccLinksBg: colors.bc1,
    accountSecondaryBackground: colors.bc5,
    accountContainerBg: '#5494eb',
    accountNavBg: colors.altBg,
    accountNavText: colors.altText,
    accountNavActive: '#ebebeb',
    accountNavActiveV2: '#442661',
    accountBodyBg: '#FFF',
    accountBodyText: '#000',
    balancesIconBg: undefined,
    accountBodyText2: colors.accountBodyText,
    accountSecondaryText: colors.altText,
    accountBorder: '#bfc9cd',
    accountHeader: colors.headingPrimary,
    accountDisabled: color(colors.secondaryText)
      .lighten(0.5)
      .string(),
    accountHighlightText: colors.bc1,
    accountHighlightTextBar: colors.success,
    accountLabel: colors.bc2,
    accountSuccess: colors.success,
    accountSectionHeaderBg: colors.bc1,
    accountSectionHeaderTextModal: colors.secondaryText,
    accountSectionHeaderText: '#fff',
    betHistoryV2expandArrow: '#442661',
    selectElementDarkBg: 'undefined',
    selectOptionColorLight: 'undefined',

    /* ************************************
      Bet history Date Filter
    ************************************ */
    dateFilterContainerBackground: '#eae9e9',
    dateFilterInputBorder: colors['grey-95'],
    dateFilterInputBackground: undefined,
    dateFilterInputColor: undefined,
    dateFilterIconColor: '#d2d2d2',
    /* ************************************
      SuccessView
    ************************************ */
    successViewTitle: colors.success,
    successViewText: colors.headerText,
    successCheckIcon: colors.success,

    /* ************************************
     Popup
     ************************************ */
    popupBg: colors.altBg,
    popupBgV2: colors.altBg,
    popupBorder: '#eee',
    popupText: colors.altText,
    popupTextV2: colors.altText,
    popupTitleText: colors.bc1,
    popupContinueButtonColor: '#f00',

    /* ************************************
     NetBetExplanation
     ************************************ */
    netBetExplanationBg: colors.bc2,
    netBetExplanationBorder: colors['grey-80'],
    netBetExplanationTopArrow: colors.success,
    netBetExplanationBottomArrow: colors.bc1,
    netBetExplanationHighlight: colors.bc1,
    netBetExplanationText: colors['grey-65'],

    /* ************************************
     Carousel Widget
     ************************************ */
    carouselTabText: 'white',
    carouselTabBg: '#0d1a26',
    carouselTabActiveBg: '#0d1a26',
    carouselTabHoverBg: '#0a202a',
    carouselTabActiveText: colors.bc1,
    carouselArrowText: 'black',
    carouselArrowBg: 'white',
    carouselArrowHoverBg: colors['grey-70'],
    carouselControlCircle: 'rgba(255, 255, 255, 0.2)',
    carouselControlArrow: 'white',
    carouselControlCircleHover: 'white',

    /* ************************************
      Special Wide Highlights
     ************************************ */
    highlightsWidgetWideTerminal: '#1e124d',
    selectionBlockInHighlights: colors.bc3,
    highlightsWideInfoTextAlt: colors.mainText,
    marketCountWideHighlights: colors.mainText,

    /* ************************************
     PasswordWrapper
    ************************************ */
    passwordWrapperInnerBg: '#fff',
    passwordWrapperTitleText: colors.bc1,
    passwordWrapperInnerShadow: '0px 0px 16px 0px rgba(0,0,0,0.5)',

    /* ************************************
     ForgotPasswordView
    ************************************ */
    forgotPasswordErrorMessage: colors.bc1,
    forgotPasswordExclamationText: '#fff',
    forgotPasswordExclamationBg: colors.error,
    forgotPasswordEmail: colors.success,
    forgotPasswordExtraIconText: colors.bc1,
    forgotPasswordEmailText: colors.bc1,

    /* ************************************
     OddsChangeConfirmation
    ************************************ */
    oddsChangeConfirmationToggle: colors.bc1,
    oddsChangeConfirmationExclamationBorder: colors['grey-70'],
    oddsChangeConfirmationExclamationText: colors.bc1,
    oddsChangeConfirmationFooterBg: colors.bc2,
    oddsChangeConfirmationBg: colors.bc2,
    oddsChangeConfirmationBorder: colors['grey-90'],

    /* ************************************
      Misc
    ************************************ */
    favouriteStar: colors.score,
    trophy: colors.bc1,
    radioItemActive: colors.bc1,
    liveActive: colors.live,
    liveInactive: colors.danger,
    forgotPassword: colors.secondaryText,

    /* ************************************
    Streaming
  ************************************ */
    streamingButtonColor: '#ffffff',
    streamingHeaderBg: colors['off-white'],
    streamingHeaderBorder: colors['grey-80'],
    streamingDetailsBg: '#ffffff',
    streamingDetailsBorder: colors['grey-80'],

    /* ************************************
      T and C Modal
    ************************************ */
    tAndCModalBg: undefined,
    tAndCModalTextLink: undefined,
    tAndCModalText: undefined,
    tAndCButtonBg: undefined,

    /* ************************************
     deposit
    ************************************ */
    depositLinkBg: colors['grey-65'],
    depositMethodHoverBg: colors.bc1,
    depositMethodActiveBg: colors.bc1,
    depositMethodCheckBg: colors.bc1,

    /* ************************************
     Deposit V2
    ************************************ */
    depositAmountViewV2Title: colors.bc1,
    depositAmountViewV2AmountBorder: colors.bc1,
    depositAmountViewV2BaseColor: colors.bc1,
    depositAmountViewV2AmountText: colors['grey-60'],

    /* ************************************
     Deposit Gateway
    ************************************ */
    depositGatewayBorder: colors.op3,
    depositGatewayActiveBorder: colors.bc1,

    /* ************************************
      Withdrawal
    ************************************ */
    withdrawalIcons: 'mediumturquoise',
    withdrawalIconExMark: colors.bc1,
    withdrawGatewayBackground: undefined,
    withdrawGatewayColor: undefined,

    /* ************************************
      PaymentsViewV3
    ************************************ */
    paymentsViewV3FormBackground: colors.bc5,
    paymentsViewV3InformationProtectionText: '#a3a3a3',
    /* ************************************
     Stauts container
    ************************************ */
    statusContainerErrorLink: colors.bc1,

    /* ************************************
    Image overlay colour
    ************************************ */
    imageOverlay: colors.bc1,

    /* ************************************
    Welcome page
    ************************************ */
    welcomeText: '#fff',
    welcomeBg: color(colors.bc1)
      .darken(0.5)
      .string(),
    welcomeButtonBg: color(colors.bc1)
      .lighten(0.6)
      .string(),
    welcomeCtaText: 'white',
    smallScreenWelcomeBg: color(colors.bc1)
      .darken(0.4)
      .alpha(0.9)
      .string(),

    /* ************************************
     Casino
    ************************************ */
    casinoTopNavBorderBottom: '#263c6d',
    casinoWrapperColor: 'unset',
    playTextIcon: '#fff',
    playTextIconBg: color('#0e6ac3')
      .alpha(0.75)
      .string(),

    /* ************************************
     Casino Game
    ************************************ */
    casinoCategoryBorder: colors.bc2,
    casinoGameMagicBorder: colors.bc1,
    casinoGameBorder: colors.op3,
    casinoGameText: '#fff',

    casinoTitle: colors.mainText,
    casinoSubtitle: colors.bc1,

    casinoDemoBtnBg: '#fff',
    casinoDemoBtnText: colors.bc1,

    casinoTabFontColor: 'unset',
    casinoTabBgColor: colors.bc3,
    casinoTabActiveBgColor: colors.bc4,
    casinoTabBoxshadow: '#000',
    casinoTabActiveFontColor: colors.bc1,
    casinoTabBorderColor: color(colors.bc3)
      .darken(0.1)
      .string(),
    casinoNambarShadow: '#000',
    casinoNambarFontColor: 'unset',
    casinoFilteredgameTextColor: 'unset',

    casinoNoFavourites: 'unset',
    /* ************************************
     Casino Left Menu
    ************************************ */
    casinoMainTitle: colors.bc1,
    casinoNameBarBg: '#00000026',
    casinoV2NameBarBg: colors.bc4,

    /* ************************************
     Casino Search Bar
    ************************************ */
    casinoSearchIcon: colors.bc1,
    casinoSearchInputBorder: colors.bc1,
    casinoSelectorBorder: undefined,
    casinoSearchBackground: '#101012',
    casinoSearchInputText: '#fff',
    casinoSearchSelectorColor: colors.mainText,
    casinoSearchInputBackground: undefined,

    /* ************************************
    Mobile Categories Grid
    ************************************ */
    mobileCategoryGridBackground: colors.fc1,
    mobileCategoryGridChevron: colors.bc1,
    mobileCategoryGridIconColor: colors.bc1,
    mobileCategoryGridIconColorActive: '#fff',
    /* ************************************
     Categories With Icon
    ************************************ */
    categoryWithIconBgActive: colors.bc1,
    categoryWithIconBottomBorder: colors.bc1,
    categoryWithIconChevron: colors.bc1,
    categoryWithIconIconColor: colors.bc1,
    categoryWithIconIconColorActive: '#fff',

    /* ************************************
    betSlipChecker
   ************************************ */
    betSlipCheckerSearchBarText: colors.mainText,
    betSlipCheckerTableHeaderBg: colors.bc3,
    betSlipCheckerTableHeaderText: colors.mainText,
    betSlipCheckerTableBodyRowBg: colors.bc3,
    betSlipCheckerTableBodyRowText: colors.headingPrimary,
    betSlipCheckerTableBodyRowBorder: colors.background,
    betSlipCheckerTableBodyRowHoverText: colors.bc1,
    betSlipCheckerTableBodyRowHoverBg: color(colors.bc3)
      .lighten(0.1)
      .string(),
    /* ************************************
    Categories Navigation
   ************************************ */

    categoriesNavigationBackground: 'transparent',
    categoriesNavigationBorder: colors.borderMain,
    categoriesNavigationLinkHover: '#fff',
    categoriesNavigationLinkText: colors.mainText,
    categoriesNavigationLinkActive: colors.bc1,

    /* ************************************
      Sports Widget Filters
    ************************************ */
    sportsWidgetFiltersButtonBg: 'rgba(255, 255, 255, 0.05)',
    sportsWidgetFiltersText: colors.mainText,
    filtersDropdownBg: color(colors.bc2)
      .alpha('0.8')
      .string(),
    filtersDropdownBorder: '#4f4f4f',
    filtersBackgroundColor: undefined,

    /* ************************************
      Events
    ************************************ */
    eventHeading: '#fff',
    eventHeaderBg: undefined,
    eventsViewMarketGroupBg: undefined,

    /* ************************************
      numericalInput
    ************************************ */
    numericalInputOddsText: colors.bc1,
    numericalInputBackLayOddsText: '#000',

    /* ************************************
      BettingKingsWidget
    ************************************ */
    bettingKingsGold: '#ffc41b',
    bettingKingsSilver: '#a7a7ad',
    bettingKingsBronze: '#a7703f',
    bettingKingsText: colors.mainText,
    bettingKingsLabelText: 'rgba(255,255,255,0.5)',
    bettingKingsHeaderItemBg: 'rgba(255,255,255,0.05)',
    bettingKingsPText: color('#ffc41b')
      .darken(0.2)
      .string(),
    bettingKingsPlaceText: 'rgba(255,255,255,0.3)',
    bettingKingsTextInBetslip: '#fff',
    bettingKingsHeaderText: colors.mainText,
    bettingKingsLabelTextInBetslip: 'rgba(255,255,255,0.5)',

    /* ************************************
      NFC card prompt
    ************************************ */
    nfcCardPromptBg: colors.bc3,
    nfcCardPromptBubbleBg: colors.bc1,
    nfcCardPromptBubbleText: colors.mainText,

    /* ************************************
      Pin Overlay
    ************************************ */
    pinOverlayHeader: colors.bc1,
    pinOverlaySearchInputBackground: color(colors.bc2)
      .lighten(0.25)
      .string(),
    terminalPinpadHeaderText: '#FFF',
    terminalPinpadText: colors.bc2,
    terminalPinPadShadow: color('#000')
      .alpha(0.5)
      .string(),

    /* ************************************
      Scoreboards
    ************************************ */
    scoreboardBackground: colors.bc1,
    scoreboardText: '#FFF',
    scoreboardClockText: '#FFF',
    scoreboardLiveClock: colors.success,

    /* ************************************
      Individual background colours for each scoreboard point field
    ************************************ */
    scoreboardPoint_0: color('#FFF')
      .alpha(0.1)
      .string(),
    scoreboardPoint_1: color('#FFF')
      .alpha(0.15)
      .string(),
    scoreboardPoint_2: color('#FFF')
      .alpha(0.2)
      .string(),
    scoreboardPoint_3: color('#FFF')
      .alpha(0.25)
      .string(),
    scoreboardPoint_4: color('#FFF')
      .alpha(0.3)
      .string(),

    /* ************************************
      rangePicker
    ************************************ */
    rangePickerBorder: colors.borderMain,

    /* ************************************
      datePicker
    ************************************ */
    datePickerBorder: colors.borderMain,

    /* ************************************
      Popular bets
    ************************************ */
    popularBetsText: colors.mainText,
    popularBetsSportHeadingBg: colors.op2,
    popularBetsBetInfoBg: colors.op1,
    popularBetsBetOddsBg: color(fade)
      .alpha(0.07)
      .hsl()
      .string(),
    popularBetsBetVsText: color(colors.mainText)
      .alpha(0.2)
      .string(),
    popularBetsBetNameText: color(colors.mainText)
      .alpha(0.6)
      .string(),

    /* ************************************
      Spin and bet Widget
    ************************************ */
    spinAndBetRowBg: colors.op1,
    spinAndBetRowSelectionText: colors.mainText,
    spinAndBetRowHoverBg: colors.op2,
    spinAndBetRowActiveBg: colors.bc1,
    spinAndBetRowActiveText: '#fff',
    spinAndBetRowlockColor: colors.op4,
    spinAndBetRowlockHoverColor: colors.op6,
    spinAndBetRowlockActiveColor: colors.bc1,
    spinAndBetRowRemoveCircle: colors.op3,
    spinAndBetRowRemoveHoverCircle: colors.op4,
    spinAndBetRowRemoveActiveCircle: colors.bc1,
    spinAndBetRowRemoveHoverText: colors.op8,
    spinAndBetBetslipBg: colors.background,
    spinAndBetStake: colors.mainText,
    spinAndBetLabelText: colors.bc1,
    spinAndBetLockHoverBg: colors.op5,

    /* ************************************
      Spin and bet Widget Box
    ************************************ */
    spinAndBetBoxBoxshadow: '#000',
    spinAndBetBgplateBase: colors.bc1,
    spinAndBetBgplateColor1: '#10c1c9',
    spinAndBetBgplateColor2: '#d97f09',
    spinAndBetBgplateColor3: '#ffdd00',
    spinAndBetBgplateColor4: '#172542',
    spinAndBetBgplateColor5: '#00a840',
    spinAndBetKeepTextColor: '#fff',
    spinAndBetKeepBg: colors.bc1,

    /** ***********************************
      Live Calendar
    ************************************ */
    liveCalendarBg: 'inherit',
    liveCalendarHeaderBg: colors.op2,
    liveCalendarEventBg: colors.op1,
    liveCalendarEventBorder: colors.background,
    liveCalendarTabActiveBg: colors.op2,
    liveCalendarTabText: colors.mainText,
    liveCalendarSelectorBg: colors.op1,
    liveCalendarSelectorText: colors.mainText,

    /* ************************************
      Fallback Image (Terminal Top Screen)
    ************************************ */
    fallbackOverlay: color(colors.bc2)
      .alpha(0.5)
      .string(),
    fallbackOverlayText: colors.mainText,

    /** ***********************************
      RangeSlider
    ************************************ */
    rangeSliderTrackBg: colors.op2,
    rangeSliderTrackActiveBg: colors.bc1,
    rangeSliderTrackFocusBg: colors.op3,
    rangeSliderThumbBg: colors.bc1,
    rangeSliderLabelText: colors.mainText,
    rangeSliderBubbletext: colors.mainText,

    /* ************************************
    MultiView
  ************************************ */
    multiViewHeaderBg: colors.op1,
    multiViewEventBg: colors.op1,

    /* ************************************
      Globals
    ************************************ */
    icon: colors.bc1,

    /* ************************************
      Countdown
    ************************************ */
    countdownLabelText: '#fff',

    /* ************************************
      Markdown
    ************************************ */
    markdownInBetslipText: '#fff',
    markDownWidgetColor: '#000',

    /* ************************************
      cashout
    ************************************ */
    cashoutErrorText: colors.error,
    cashoutButton: '#bbb !important',
    cashedOutCheck: colors.success,

    /* Responsible Gaming */
    responsibleGamingText: colors.secondaryText,
    responsibleGamingTextHover: colors.secondaryText,
    responsibleGamingBottomBorder: colors.borderMain,

    /* ************************************
      Arena common
    ************************************ */
    tournamentRowText: colors.secondaryText,
    tournamentRowBg: colors.tournamentRowBg,
    tournamentClosedText: colors.error,
    tournamentBorderColor: '#d9d9d9',
    tournamentCommonBorder: colors['grey-95'],
    socialIcon: 'white',

    /* ************************************
      tournament component
    ************************************ */
    tournamentLiveText: colors.live,
    tournamentBuyInText: '#fff',
    tournamentBuyInBg: bc.bc1,
    tournamentLiveInverseText: '#fff',
    bioTextBackground: colors.bc3,
    userSctionBackground: colors.bc3,
    tournamentLeaderboardTitleBg: colors.bc1,
    tournamentLeaderboardRow: colors.bc3,
    gridSpaceBetweenRowsBg: 'unset',
    gridSpaceBetweenBg: 'unset',
    gridSpaceBetweenText: 'unset',
    tournamentLeaderboardRowBg: 'unset',

    /* ************************************
      tournament detail view
    ************************************ */
    tournamentAvailableText: colors.live,
    tournamentUnavailableText: colors.error,

    /* ************************************
      TournamentView
    ************************************ */
    tournamentViewTitleText: '#000',
    tournamentViewFieldText: colors.secondaryText,
    tournamentViewMenuItemIcon: colors.score,
    tournamentPaginationBg: 'transparent',
    tournamentPaginationBorder: colors.borderMain,
    tournamentPaginationText: colors.secondaryText,
    tournamentPaginationNavDisabledBg: colors.buttonDisabledBg,
    tournamentConfirmModalConfirmText: 'green',
    tournamentConfirmModalCancelText: colors['grey-15'],
    tournamentCreateBtnBg: '#ef6c00',

    /* ************************************
      Tournament Prize View
    ************************************ */
    tournamentPrizeViewHeaderText: colors.secondaryText,
    tournamentPrizeLabelText: '#000',
    tournamentPrizeValueText: colors.live,

    /* ************************************
      Tournament Leaderboard View
    ************************************ */
    tournamentLeadeboardText: '#000',

    /* ************************************
      Tournament Chat View
    ************************************ */
    tournamentChatText: '#000',
    tournamentChatBg: '#fff',
    tournamentChatMessageText: colors['grey-50'],
    tournamentChatDateTimeText: colors['grey-70'],
    tournamentChatInputText: colors['grey-60'],
    tournamentChatSendBtnColor: '#ffc41b',
    /* ************************************
      Tournaments Results View
    ************************************ */
    tournamentRankTableText: 'black',
    tournamentRankTdBorder: colors['grey-70'],
    tournamentRankHeadTrBg: colors['grey-95'],
    tournamentRankTrOddBg: colors['off-white'],
    tournamentRankTrEvenBg: colors['grey-95'],

    /** ****************************************
          Arena - Leaderboard
     ***************************************** */

    leaderboardRankTableText: '#fff',

    /* ************************************
      User Profile View
    ************************************ */
    userProfileText: colors.secondaryText,
    userProfileBg: '#fff',
    userProfileFriendText: '#fff',
    userProfileFindFriendText: colors.secondaryText,
    userProfileOpenTabText: bc.bc1,
    userProfilePendingText: '#65385a',

    /* ************************************
      Friend Card
    ************************************ */
    friendCardText: colors.secondaryText,
    friendCardBg: '#fff',
    friendCardPendingText: '#65385a',
    friendCardAlreadyFriendsText: colors.success,

    /* ************************************
      Tournaments No Data Message
    ************************************ */
    noDataMessageText: colors.secondaryText,

    /* ************************************
      Add Public/Private Tournaments View
    ************************************ */

    fieldBg: '#fff',
    selectedListText: '#fff',
    formValidationText: '#fff',
    formValidationBorder: 'red',
    formValidationBg: '#d1212b',
    dropdownText: '#000000',
    dropdownItemHovBg: '#f9f9f9',
    friendTick: '#0e9e0e',

    /* ************************************
      chat
    ************************************ */
    tawkBg: colors.bc1,
    tawkText: '#fff',

    /* ************************************
    blitzbet_arena
    ************************************ */
    blitzbetArenaMainBg: '#292929',
    blitzbetArenaSecondaryBg: '#292929',
    blitzbetArenaAltBg: '#fff',
    blitzbetArenaMainText: '#fff',
    blitzbetArenaMainTextSub: '#000',
    blitzbetArenaSecondaryText: '#fbad18',
    blitzbetArenaHeaderText: '#fff',
    blitzbetArenaMenuTitle: '#000',
    blitzbetArenaAltText: '#292929',
    blitzbetArenaChallengeView: '#000',
    blitzbetArenaChallengeViewBg: '#fff',
    blitzbetArenaMainBorder: '#999',
    blitzbetArenaSecondaryBorder: '#eee',
    blitzbetArenaFooterMenuBg: '#212121',
    blitzbetArenaFooterMenuBorder: '#111',

    /* ************************************
      Pending Withdrawals
    ************************************ */
    pendingWithdrawalsCount: colors.bc2,
    peningWithdrawalsIconCountCircleFill: '#fff',
    categoriesNavigationBackgroundPadded: '#fff',
    eventHeaderContainerPadded: '#fff',
    caruselBgPadded: 'rgba(255,255,255,0.5)',
    markDownWidgetPadded: '#fff',
    headerGradeintBg: `linear-gradient(135deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 15%, ${colors.bc1} 20%, ${colors.bc1} 100%)`,

    selectionViewBorder: colors.bc1,
    paddedBodyBg: 'unset',
    tournamentHeaderBg: colors.bc1,
    tournamentHeaderText: '#fff',
    selectOptionColor: 'unset',
    joinNowButtonBg: 'rgb(225,194,0)',
    joinNowButtonText: '#fff',
    tournamentsResultsViewText: '#fff',
    tournamentsResultsViewBg: colors.bc1,
    sidebarItemsBg: 'unset',
    sideBarIconColor: 'unset',
    itemCountColor: 'unset',
    betslipInnerContainerBg: 'unset',
    sidebarItemBorderColor: 'transparent',
    tournamentWrapper1Bg: '#ebebeb',
    paddedBodyCenterBg: 'unset',
    betsliptabsTabActiveBg: 'unset',
    betsliptabTabActiveBg: 'unset',
    betslipSeparatedTabsColor: 'unset',
    headerClearHover: 'unset',
    casinoTopNavContainerBg: 'unset',
    liveCalendarText: 'unset',
    resultContainerText: 'unset',
    liveCasinoWrapperBg: 'unset',
    burgerCustomBg: 'unset',
    betslipIconCountColor: '#fff',
    selectionViewBorderColor: 'unset',
    selectionViewEventPageBorderColor: 'unset',
    bonusProgressBarPercentColor: '#ffffff',
    headerButtonsV2Bg: 'transparent',
    headerButtonsV2BorderColor: '#fff',
    headerButtonsV2Text: '#fff',
    headerButtonsV2TextSignIn: colors.headerButtonsV2Text,
    forgotPasswordHeaderLinkText: '#fff',
    headerButtonsV2RegisterBg: 'transparent',
    eventColumnBg: 'unset',
    selectionViewFirstElementColor: 'unset',
    selectionViewLine: 'inherit',
    titleSecondText: 'inherit',
    widgetSubtitle: 'inherit',
    widgetHeaderWrapperBg: '#fff',
    loginViewWrapperText: '#fff',
    loginViewContentWrap: '#001c42',
    priorityNavBarWrapperBg: 'unset',
    betslipTabsTitleColor: '#1782f0',
    betslipTabsBgV7: '#001c42',
    singleBetRemoveIconColor: '#878787',
    singleBetSelectionOddsColor: '#1782f0',
    betslipStakePopupStakeButtonColor: '#f1f5f9 !important',
    betslipStakePopupStakeLabelColor: '#001c42 !important',
    betslipStakePopupContainerBg: '#f1f5f9',
    userDropDownViewClickContainerBg: undefined,
    userDropDownViewClickContainerColor: undefined,
    betslipGroupTitleTextSpan: '#1782f0',
    loginViewV3registerBtnBorder: '#00ff79',
    switchLabel: '#001c42',
    buttonColor2: undefined,
    buttonColor3: undefined,

    // wordpress widget
    newsBackground: color.bc2,
    newsBorder: color.mainText,
    newsText: color.mainText,
    newsShowMoreText: color.mainText,

    numInputMobilePlaceholderBg: '#333',
    numInputMobilePlaceholderText: colors.bc1,

    // lobby
    lobbyBg: undefined,

    betslipTabActiveBorderColor: 'transparent',

    // Stand-alone app download view
    downloadAppButtonColor: 'white',
    downloadAppButtonBorderColor: '#bcd800',
    downloadAppModalBackgroundColor: '#000000',
    downloadAppModalBackgroundColorv2: '#fff',
    downloadAppTitleColor: '#f84d4c',

    /* ************************************
      virtual sports section
     ************************************ */
    virtualsTabText: colors.bc1,
    virtualsTabActiveBg: colors.bc1,
    virtualsTabActiveText: '#fff',

    /* ************************************
      Promotions Top Tabs View
     ************************************ */
    promotionsTopTabsLabelColor: colors.fc1,

    /* ************************************
      General Tabs V2
     ************************************ */
    generalTabsV2Background: '#ebebeb',
    generalTabsV2TabFontColor: 'unset',
    generalTabsV2BorderBottom: colors.bc1,
    generalTabsV2ActiveBackground: colors.bc1,
    generalTabsV2ActiveText: colors.bc3,

    generalTabsTypesV2: [
      {
        name: 'tabs1',
        Bg: '#ebebeb',
        Text: 'unset',
        borderBottom: colors.bc1,
        activeBg: colors.bc1,
        activeText: colors.bc3,
      },
      {
        name: 'tabs2',
        Bg: '#000000bf',
        borderBottom: colors.fc1,
        activeBg: colors.fc1,
      },
    ],
    generalTabsV2TextLight: '#fff',
    generalTabsV2TextDark: colors.fc1,
    /* ************************************
      Button Types V2
     ************************************ */
    buttonTypesV2: [
      {
        name: 'button1',
        Bg: colors.bc1,
        Text: '#fff',
        shadow: true,
      },
      {
        name: 'button2',
        Bg: '#0832c8',
        shadow: true,
      },
      {
        name: 'button3',
        Bg: colors.bc1,
        Text: '#fff',
      },
      {
        name: 'button4',
        Bg: '#fff',
        Text: colors.bc1,
        shadow: true,
      },
      {
        name: 'button5',
        Bg: '#fff',
        Text: '#000',
        border: 'grey',
      },
      {
        name: 'button6',
        Bg: '#fff',
        Text: colors.bc1,
        border: '#e0e0e0',
      },
    ],
    buttonTypesV2TextLight: '#fff',
    buttonTypesV2TextDark: colors.fc1,

    /* ************************************
      Labels
     ************************************ */
    mirrorLabelText: '#021c52',

    cardAsianCard5BorderLeft: colors.bc2,
    betslipClearButtonTextHover: '#fff',
    betslipClearBottomButtonTextHover: undefined,

    priceBlockBoldColor: undefined,

    marketHeaderGradientBg1: 'rgba(14,106,195,1)',
    marketHeaderGradientBg2: 'rgba(41,128,212,1)',

    eventCodesColor: colors.bc1,
    FooterViewV2Color: undefined,
    marketsHeaderBg: undefined,
    filtersBg: undefined,
    filtersOptionBg: undefined,
    europeanMarketsViewColor: undefined,
    infoBoxHighlightedScoreBg: undefined,
    marketNumbersNumWithTextColor: undefined,
    betslipInnerContainerBg: undefined,
    pageTitleColor: undefined,
    pagingDotsColor: colors.bc1,
    casinoHeaderBg: '#111112',
    casinoHeaderText: '#fff',
    CasinoHeaderShadow: '0px 6px 10px 6px rgb(0, 0, 0)',
    quickSengleColor: '#fff',
    bottomMenuBaseColor: colors.bc1,
    betslipBgSmall: isLight ? '#e1e2e5' : '#000',
    DepositViewV2Color: undefined,
    loggedOutV3RegisterBg: undefined,
    loggedOutV3RegisterColor: undefined,
    loggedOutV3LoginColor: undefined,
    loggedOutV3LoginBg: undefined,
    cardAsianBg: undefined,
    selectionViewBorderColor: undefined,
    casinoGameBg: undefined,
    compHeaderBg: '#474747',
    betslipTabsBgMobil: undefined,
    formSelectedBg: 'transparent',
    tabInner: undefined,
    searchFieldColor: undefined,

    /* ************************************
      Floating Button
     ************************************ */
    floatingButtonMainCircle: '#5494ea',
    floatingButtonMainCircleShadow: '#316AB8',
    floatingButtonMainCircleBorder: '#316AB8',
    floatingButtonTextColor: '#FFF',
    floatingButtonUpperCircle: '#FF5700',
    floatingButtonLowerCircle: '#263B6D',

    /* ************************************
      Messages Form
     ************************************ */
    messagesFormContainer: '#F9F9F9',
    messagesFormTextareaBorder: 'rgb(209, 213, 219)',

    /* ************************************
      Messages Table
     ************************************ */
    messagesTableRowEven: '#FFF',
    messagesTableRowOdd: '#F9F9F9',
    messagesNewLabel: '#EC5436',
    messagesNewLabelText: '#FFF',
    messagesCellContentText: '#000',

    categoriesSection: isLight ? '#000' : '#fff',

    /* ************************************
    Streaming V2
    ************************************ */
    streamingContentBackground: '#263B6D',
    streamingContainerBackground: '#001953',
    streamingText: '#fff',
    priorityNavWrapContainerBoreder: 'transparent',
    selectionViewTransparentTextColor: '#fff',
    selectionViewTransparentBg: 'rgba(255, 255, 255, 0.2) !important',
    betradarScoreboardBg: undefined,
    openBetContainerBg: undefined,
    marketTypeSelectorBg: undefined,
    oddsDropdownValueColor: undefined,
    marketTypeSelectorBorder: '1px solid #ccc',

    /* ************************************
    ATS UI
    ************************************ */
    atsUi: {
      tabs: {
        text: '#626163',
        selectedBg: '#583712',
        selectedText: '#fff',
        border: '#1D3158',
      },
    },

    /* ************************************
    SINGLE V2
    ************************************ */

    singleV2: {
      dot: undefined,
      selectionName: undefined,
      odds: undefined,
      eventName: undefined,
      eventTime: undefined,
      marketName: undefined,
      labelBackground: undefined,
      labelColor: undefined,
      labelValue: undefined,
    },

    /* ************************************
    EVENT DAY FILTER
    ************************************ */

    eventDayFilterActiveColor: '#1782f0',

    /* ************************************
    ANTD TAB WIDGET
    ************************************ */

    antTabsTab: undefined,
    antTabsCard: undefined,

    verticalLinksColor: undefined,
    socialLinksColor: undefined,
    verticalLinksHoverColor: undefined,
    selectElementIconColor: undefined,
    numericalInputColor: colors.bc1,
    betslipMaxTextHover: undefined,
    autodocButton: undefined,
    betPartsV2Color: undefined,
    modalBgColor: undefined,
    betslipConfrimationOddsTitleText: undefined,
    betslipConfirmationLabelColor: undefined,
    betslipConfirmationHrColor: undefined,
    betslipConfrimationButtonBorderColor: undefined,
    scoreboardPointsColor: undefined,
    scoreboardLiveBorderColor: undefined,
    MainViewForgotPasswordBg: undefined,
    defaultStackButtonsContainerBg: undefined,
    defaultStackButtonsContainerColor: undefined,
    useDropDownViewBg: undefined,
    europeanMarketViewStripe: undefined,
    accountAreaButtonV3Color: undefined,
    acceptOddsV2Label: undefined,
    acceptOddsV2Value: undefined,
    summaryV2Label: undefined,
    summaryV2Value: undefined,
    defaultStakeButtonsLabel: undefined,
    backgroundSmallContainerColor: undefined,
    betslipConfrimationTitleText: colors.bc1,
    betslipConfrimatioBetOddsColor: colors.bc1,
    betslipConfrimationOddsDateText: colors.bc1,
    betslipConfirmationV2TextColor: undefined,
    eventViewMarketSelectorBg: undefined,
    betslipV2BetsContainerBg: undefined,
    betIdColor: '#000',
    placeBetContainerBg: undefined,
    linkHighlightColor: colors.bc1,
    linkHoverHighlightColor: colors.bc1,
    selectionOddsColor: undefined,
    selectionOddsColorLost: undefined,
    openBetsHrColor: undefined,
    openBetPayoutLabelColor: undefined,
    openBetColumnColor: undefined,
    itemInSportMenuActiveBg: undefined,
    itemInSportMenuBeforeBg1: '#c1a472',
    itemInSportMenuBeforeBg2: '#433423',
    itemInSportMenuBorderColor: '#36220b',
    itemInSportMenuAfterBg: '#080f24',
    betslipCombiBetsWarningInvalidBg: undefined,
    modalCloseIconDark: '#000',
    modalCloseIconLight: '#fff',
    betslipConfrimationFollowText: colors.bc1,
    betslipCombiBetInvalid: undefined,
    mobileLinksWidgetBg: undefined,
    mobileLinksWidgetShadow: undefined,
    mobileLinksWidgetBorder: undefined,
    selectionViewParticipant: undefined,
    mobileLinksWidgetColor: colors.bc1,
    scoreboardParticipantsV2Color: undefined,
    playersBackground: colors.background,
    goalScorerMarketRowsBg: undefined,
    goalScorerNameColor: undefined,
    goalScorerBg: colors.bc3,
    goalScorerMarketViewColor: undefined,
    modalWrapperBg: undefined,
    bonusDetailsBg: '#ebebeb',
    playerPropsMarketTeamViewBg: undefined,
    europeanMarketsViewSpanColor: undefined,
    selectionViewParticipnatsBg: undefined,
    selectionViewParticipnatsNameBg: undefined,
    marketRowBgOutrights: colors.op1,
    outrightViewBorderBottom: colors.background,
    betslipAdjustsSelectorBorderSelected: undefined,
    teaserBetsRowBorder: undefined,
    gradientTextColor1: '#fffaed',
    gradientTextColor2: '#a18358',
    playerPropsMarketTypeViewFooter: undefined,
    marketsHeaderTitle: undefined,
    placeBetOddsChange: '#62a914 !important',
    oddsChangeColor: '#f0f0f0',
    betslipCloseButtonBg: undefined,
    burgerMenuBorderRight: colors.headerBorder,
    priceBlockOddsChangeBg: '#41b590',
    betslipTabsTabHoverBg: '#108950',
    betTypeTabsTabHoverBg: '#172d4c',
    betTypeTabsTabColor: '#fff',
    teaserBetsColorHover: undefined,
    fullCashoutTabBg: 'none !important',
    limitHistoryItemRowBg: '#fff',
    limitHistoryItemRowColor: undefined,
    depositLimitBoxTextColor: '#000 !important',
    responsibleGamingColor: '#000',
    filterAccountBodyText: colors.accountBodyText,
    successViewColor: undefined,
    betSlipPlaceButtonBg: undefined,
    betSlipPlaceButtonColor: undefined,
    betSlipRewardsButtonsBackgroundColor: undefined,
    bottomMenuActiveColor: undefined,
    inPlayCounterBg: undefined,
    europeanMarketsViewPeriodContainerColor: undefined,
    collapseHeaderBg: colors.bc1,
    collapseHeaderColor: '#fff',
    rowNodeNumEventsBg: undefined,
    casinoWidgetNoResultColor: undefined,
    carouselWidgetSliderBg: undefined,
    headerButtonsV2RegisterBgV2: undefined,
    headerButtonsV2TextV2: undefined,
    registerRowInputBg: undefined,
    registerRowInputColor: undefined,
    selectContainerBg: '#fff',
    selectElementIconBg: undefined,
    burgerContainerBg: colors.burgerBg,
    burgerSectionBg: colors.burgerBg,
    liveChatContainerColor: '#000',
    liveChatClickMatchesDivActiveBg: '#1782f0',
    liveChatClickMatchesDivActiveColor: '#fff',
    liveChatMatchTrackerColor: '#000',
    liveChatTopLiveChatContainerBg: '#001c42',
    liveChatClickMoreColor: '#d8d8d8',
    liveChatMessageContentBg: '#c4d5e5',
    liveChatMessageContentColor: '#001c42',
    liveChatMessageListBg: '#fff',
    liveChatMessageContentFromUserBg: '#f1f5f9',
    liveChatDateTimeColor: '#001c42',
    liveChatMessageInputColor: '#001c42',
    liveChatTopLiveChatContainerColor: '#fff',
    liveChatSendMessageColor: '#fff',
    liveChatSendMessageDivBg: '#1782f0',
    liveChatMessageContainerBg: '#fff',
    liveChatMessageInputBg: '#fff',
    timelyLimitTabText: undefined,
    CTAfirstButtonBg: undefined,
    CTAsecondButtonBg: undefined,
    CTAsecondButtonColor: undefined,
    editImageSelect: '#1782f0',
    comboBoostAmount: '#ffc42c',
    selectionSelectedBlockBg: undefined,
    emptyPublicBetsText: '#4f4f4f',
    overUnderMarketViewHeaderBg: undefined,
    OverUnderNameColor: undefined,
    OverUnderMarketViewColor: undefined,
    selectionViewSelectionVerticalBg: undefined,
    selectionVertical: undefined,
    sportsWidgetBaseScrollContainerBg: undefined,
    headerMinimalViewMobileRegisterBg: '#00ff79',
    userDropDownViewMobileLoginBg: undefined,
    userDropDownViewClickContainerV2Bg: undefined,
    inputWrapperCurrencyColor: undefined,
    selectionParticipantSelectedBg: undefined,
    selectionVertical2: undefined,
    selectionPriceBlockAltBg: undefined,
    priceBlockSelectedBg: undefined,
    priceBlockBoldColorHover: undefined,
    priceBlockBoldTextHover: undefined,
    positiveTransactionRow: undefined,
    negativeTransactionRow: undefined,
    casinoTopNavBg: undefined,
    notActiveSocialTabFilter: '#ddeeff',
    feedsWidgetFollowersText: '#506888',
    itemLabelColor: '#5494eb',
    boxContainerBg: '#263b6d',
    boxMarketNameColor: '#5494ea',
    boxPrematchBg: '#5494ea',
    selectionPriceBlockAltBgJapan: '#263B6D',
    loginButtonV2Bg: '#0932C8 !important',
    socialBetPartEventTime: '#6c6c6c',
    feedsWidgetSecondMainColor: '#1782f0',
    feedsWidgeMainTextColor: '#001c42',
    socialFeedPrimaryButtonBg: '#00ff79',
    feedsWidgetSelectionOdds: '#1782f0',
    spinWheelBG: undefined,
    feedProfileHeader: '#f1f5f9',
    recommendedBg: '#001c42',
    socialFeedBetButtonBg: '#001c42 !important',
    priceBlockOutrightBg: undefined,
    socialProfileFilterBg: '#f1f5f9 !important',
    socialProfileFilterSelectedBg: '#f1f5f9 !important',
    headerMinimalViewBg: undefined,
    priorityNavItemHeaderBg: undefined,
    headerMinimalViewV3Bg: '#0932c8',
    headerMinimalViewIconFill: '#5494EB',
    headerMinimalViewColor: '#5494EB',
    menuItemLabelColor: '#fff',
    menuItemsvgActiveFill: '#5494ea',
    searchFieldInputBg: '#fff',
    burgerMenuItemColorHover: '#5494ea',
    burgermenuViewTitleColor: '#5494ea',
    checkboxCheckedBg: '#1782f0',
  };

  const mergeColors = { ...componentColors, ...skinComponentColors };

  const colorKeys = Object.keys(componentColors);
  // remove any keys that are not in here so people must maintain this file
  const cleanColors = _.pick(mergeColors, colorKeys);

  const warnAboutColors = () => {
    Object.keys(componentColors).forEach(key => {
      if (
        !_.isEmpty(skinComponentColors) &&
        _.isUndefined(skinComponentColors[key])
      ) {
        console.warn(
          `Key ${key} is missing or undefined from skin colors. Using default from root colors.js as a fallback!`,
        );
      }
    });
  };

  if (process.env.NODE_ENV === 'development') {
    // check no keys are provided by skin that we dont have as a default.
    const badKeys = Object.keys(_.omit(mergeColors, colorKeys));
    console.warn(
      "The following keys exist in this skin's `skinColors.js` but not in the base `colors.js`:",
      badKeys.join(', '),
    );

    // check for keys that exist here but not in skinColors
    if (!_.isEmpty(skinComponentColors)) {
      // Suppress colors warnings until colors refactor.
      // warnAboutColors();
    } else {
      console.warn(
        'No skinComponentColors found. This might be the base repo...',
      );
    }
  }

  return { ...cleanColors };
};

export default setColors;
